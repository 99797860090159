import { getHomeRoute, goNextRoute } from '@/config/router';

export default async function guest({ to, from, store }) {
  const userIsDummy = store.getters['Account/getSettingsValue']('user.is_dummy');
  let nextRoute = null;

  if (store.getters['Account/isAuthorized']() && !userIsDummy) {
    const { destination } = to.query;
    nextRoute = destination ? { path: decodeURI(destination) } : getHomeRoute();
  }

  return goNextRoute(from, nextRoute);
}

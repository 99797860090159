<script>
export default {
  data() {
    return {
      countFrames: 10,
      displayPriority: 0,
    };
  },
  mounted() {
    this.runDisplayPriority();
  },
  methods: {
    runDisplayPriority() {
      const step = () => {
        requestAnimationFrame(() => {
          this.displayPriority++;
          if (this.displayPriority < this.countFrames) {
            step();
          }
        });
      };
      step();
    },
    defer(priority) {
      return this.displayPriority >= priority;
    },
  },
};
</script>

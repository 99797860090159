// Attach an event listener to an element
export const eventOn = (el, eventName, handler, options) => {
  if (el && el.addEventListener) {
    el.addEventListener(eventName, handler, options)
  }
}

// Remove an event listener from an element
export const eventOff = (el, eventName, handler, options) => {
  if (el && el.removeEventListener) {
    el.removeEventListener(eventName, handler, options)
  }
}

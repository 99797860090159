import { helpers } from 'vuelidate/lib/validators';
import { isEmptyValue } from '@/shared/utils';

export const regexTel = /^(\+)?([0-9 ()-]+)$/;
export const regexEMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export function validateEmail(email) {
  return regexEMail.test(String(email).toLowerCase());
}

export const vTel = helpers.regex('vTel', regexTel);

export const vNotEmpty = (value) => {
  return !isEmptyValue(value);
};

export async function isEntityUnique(value, originValue, checkFunc) {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  let isSame;

  if (typeof value === 'string') {
    isSame = originValue && trimmedValue.toLowerCase() === originValue.toLowerCase();
  } else {
    isSame = originValue && trimmedValue === originValue;
  }

  if (!trimmedValue || isSame) {
    return true;
  }

  let res = false;

  try {
    const response = await checkFunc(trimmedValue);
    res = !(response?.data || []).length;
  } catch (error) {
    console.log(error);
  }

  return res;
}

// check invalid params id
function isValidParamsId(paramsId) {
  return /^\d+$/.test(paramsId) && Number(paramsId) < 10 * 1.0e+8;
}

function hasInvalidParams(to) {
  return !!(to.params.id && !isValidParamsId(to.params.id));
}

export function checkInvalidParams(next, to) {
  if (hasInvalidParams(to)) {
    next({
      name: 'Page404',
    });
    return true;
  }

  return false;
}

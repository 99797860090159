import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

const ClientsList = () => import(/* webpackChunkName: "pm-clients" */ '@/views/parabyg-migration/clients/ClientsList');
const ClientEditor = () => import(/* webpackChunkName: "pm-client-editor" */ '@/views/parabyg-migration/clients/ClientEditor');
const ClientVersionsList = () => import(/* webpackChunkName: "pm-client-versions" */ '@/views/parabyg-migration/client-versions/ClientVersionsList');
const ClientVersionEditor = () => import(/* webpackChunkName: "pm-client-version-editor" */ '@/views/parabyg-migration/client-versions/ClientVersionEditor');
const CompaniesList = () => import(/* webpackChunkName: "pm-companies" */ '@/views/parabyg-migration/companies/CompaniesList');
const ContactsList = () => import(/* webpackChunkName: "pm-contacts" */ '@/views/parabyg-migration/contacts/ContactsList');
const DocumentsList = () => import(/* webpackChunkName: "pm-documents" */ '@/views/parabyg-migration/documents/DocumentsList');
const ActivitiesList = () => import(/* webpackChunkName: "pm-activities" */ '@/views/parabyg-migration/activities/ActivitiesList');
const EmployeesList = () => import(/* webpackChunkName: "pm-employees" */ '@/views/parabyg-migration/employees/EmployeesList');
const ProjectsList = () => import(/* webpackChunkName: "pm-projects" */ '@/views/parabyg-migration/projects/ProjectsList');
const CustomFieldsList = () => import(/* webpackChunkName: "pm-custom-fields" */ '@/views/parabyg-migration/custom-fields/CustomFieldsList');
const CustomFieldSelectionsList = () => import(/* webpackChunkName: "pm-custom-field-selections" */ '@/views/parabyg-migration/custom-fields/CustomFieldSelectionsList');
const CustomFieldValuesList = () => import(/* webpackChunkName: "pm-custom-field-values" */ '@/views/parabyg-migration/custom-fields/CustomFieldValuesList');
const CustomFieldEmployeesList = () => import(/* webpackChunkName: "pm-custom-field-employees" */ '@/views/parabyg-migration/custom-fields/CustomFieldEmployeesList');

export default [
  {
    meta: {
      breadcrumb: { name: 'Parabyg migration' },
      dictKey: 'DashboardContainer',
      menuId: 78,
    },
    path: '/parabyg-migration',
    redirect: '/parabyg-migration/clients',
    name: 'ParabygMigration',
    component: DashboardContainer,
    children: [
      {
        path: 'clients',
        redirect: '/parabyg-migration/clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'parabyg_migration',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Clients',
              },
              dictKey: 'ParabygMigration',
              menuId: 79,
            },
            path: '/parabyg-migration/clients',
            name: 'PMClients',
            component: ClientsList,
          },
          {
            meta: {
              breadcrumb: { name: 'Create client' },
              component: 'parabyg_migration',
              middleware: [validateComponent],
              dictKey: 'ParabygMigration',
              parentMenuId: 79,
            },
            path: 'new',
            name: 'PMClientCreate',
            component: ClientEditor,
          },
        ],
      },
      {
        path: 'client-versions',
        redirect: '/parabyg-migration/client-versions',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'parabyg_migration',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Client versions',
              },
              dictKey: 'ParabygMigration',
              menuId: 80,
            },
            path: '/parabyg-migration/client-versions',
            name: 'PMClientVersions',
            component: ClientVersionsList,
          },
          {
            meta: {
              breadcrumb: { name: 'Create client version' },
              component: 'parabyg_migration',
              middleware: [validateComponent],
              dictKey: 'ParabygMigration',
              parentMenuId: 80,
            },
            path: 'new',
            name: 'PMClientVersionCreate',
            component: ClientVersionEditor,
          },
        ],
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Projects' },
          dictKey: 'ParabygMigration',
          menuId: 81,
        },
        path: '/parabyg-migration/projects',
        name: 'PMProjects',
        component: ProjectsList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Companies' },
          dictKey: 'ParabygMigration',
          menuId: 82,
        },
        path: '/parabyg-migration/companies',
        name: 'PMCompanies',
        component: CompaniesList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Contacts' },
          dictKey: 'ParabygMigration',
          menuId: 83,
        },
        path: '/parabyg-migration/contacts',
        name: 'PMContacts',
        component: ContactsList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Employees' },
          dictKey: 'ParabygMigration',
          menuId: 84,
        },
        path: '/parabyg-migration/employees',
        name: 'PMEmployees',
        component: EmployeesList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Documents' },
          dictKey: 'ParabygMigration',
          menuId: 85,
        },
        path: '/parabyg-migration/documents',
        name: 'PMDocuments',
        component: DocumentsList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Activities' },
          dictKey: 'ParabygMigration',
          menuId: 86,
        },
        path: '/parabyg-migration/activities',
        name: 'PMActivities',
        component: ActivitiesList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom fields' },
          dictKey: 'ParabygMigration',
          menuId: 87,
        },
        path: '/parabyg-migration/custom-fields',
        name: 'PMCustomFields',
        component: CustomFieldsList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom field values' },
          dictKey: 'ParabygMigration',
          menuId: 88,
        },
        path: '/parabyg-migration/custom-field-values',
        name: 'PMCustomFieldValues',
        component: CustomFieldValuesList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom field selections' },
          dictKey: 'ParabygMigration',
          menuId: 89,
        },
        path: '/parabyg-migration/custom-field-selections',
        name: 'PMCustomFieldSelections',
        component: CustomFieldSelectionsList,
      },
      {
        meta: {
          component: 'parabyg_migration',
          middleware: [validateComponent],
          breadcrumb: { name: 'Custom field employees' },
          dictKey: 'ParabygMigration',
          menuId: 90,
        },
        path: '/parabyg-migration/custom-field-employees',
        name: 'PMCustomFieldEmployees',
        component: CustomFieldEmployeesList,
      },
    ],
  },
];

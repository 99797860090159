export default {
  state: {
    isLoadingSidebarButton: false,
  },
  getters: {},
  mutations: {
    IS_LOADING_SYNC_DICT(state, payload) {
      state.isLoadingSidebarButton = payload;
    },
  },
  namespaced: true,
};

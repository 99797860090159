import miniToastr from 'mini-toastr';

const defaultOptions = {
  types: {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn',
  },
  allowHtml: true,
};

export default {
  install(Vue, opts) {
    const options = { ...defaultOptions, opts };

    miniToastr.init(options);

    Vue.prototype.$notifToastr = (type, message, title = null, timeout = 5000, cb = undefined) => {
      if (navigator.onLine) {
        miniToastr[type](message, title, timeout, cb);
      }
    };
  },
};

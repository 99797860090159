import store from "@/store";

export function checkMaintenanceMode(next, to) {
  if (store.state.App.isMaintenanceMode) {
    next(to.name !== 'MaintenanceMode' ? {
      name: 'MaintenanceMode',
    } : undefined);
    return true;
  }

  return false;
}

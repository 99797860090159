import Vue from 'vue';
import Vuex from 'vuex';
import createMutationsSharer from 'vuex-shared-mutations';
import modules from './modules';
import { CookiesStorage } from "@/shared/storage/drivers/cookiesStorage";
import { createPersistedStorage } from "@/store/persisted-storage";

Vue.use(Vuex);

const persistedSessionStorage = createPersistedStorage({
  storageDriver: window.sessionStorage,
  storeModules: modules,
  persistModules: [
    'UserSearches',
    'EntityList',
    'Entity',
    'PrintSettings',
  ],
});

const persistedLocalStorage = createPersistedStorage({
  storageDriver: window.localStorage,
  storeModules: modules,
  persistModules: [
    'App',
    'Account',
    'NotifQueue',
    'Login',
    'PageSections',
    'UsageStats',
  ],
});

export default new Vuex.Store({
  modules,
  actions: {
    // reset({ commit }) {
    //   // resets state of all the modules
    //   Object.keys(modules).forEach(moduleName => {
    //     commit(`${moduleName}/RESET`);
    //   })
    // },
    resetAuthData({ commit }) {
      commit('Account/RESET_ACCOUNT');
      commit('CLEAR_USER_SEARCHES');
      commit('UserClient/RESET');
      commit('RESET_ENTITIES');
      commit('RESET_ENTITY_LISTS');
      commit('UsageStats/RESET');
      commit('UserTags/RESET');
      commit('Modules/RESET');
      commit('Dashboard/RESET');

      window.sessionStorage.clear();
      CookiesStorage.remove('OPID');
    },
  },
  plugins: [
    persistedSessionStorage,
    persistedLocalStorage,
    createMutationsSharer({
      predicate: [
        'Account/SET_USER_SETTINGS',
        'Account/UPDATE_USER_SETTINGS',
        'Account/RESET_ACCOUNT',
      ],
    }),
  ],
});

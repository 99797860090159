import _ from 'lodash';
import Vue from 'vue';

export default {
  state: {
    triggersPredictions: {},
  },
  getters: {
    getTriggerPredictions: (state) => (triggerTypeId) => {
      return _.get(state.triggersPredictions, `${triggerTypeId}`);
    },
    getTriggerPrediction: (state) => (triggerTypeId, triggerId) => {
      return _.get(state.triggersPredictions, `${triggerTypeId}.${triggerId}`);
    },
  },
  mutations: {
    SET_TRIGGER_PREDICTION(state, payload) {
      const { triggerTypeId, triggerId, data } = payload;

      if (triggerTypeId && triggerId) {
        const predictions = state.triggersPredictions[triggerTypeId] || {};
        Vue.set(state.triggersPredictions, triggerTypeId, {
          ...predictions,
          [triggerId]: data,
        });
      }
    },
  },
  actions: {

  },
  // namespaced: true,
};

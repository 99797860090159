const initialState = () => ({
  listsSettings: {},
  entitySettings: {},
});

/**
 *
 * @param {*} state the current module state
 * @param {*} payload the data update payload
 */
function setSettingsValue(data, payload) {
  const { typeId, fields } = payload;

  Object.assign(data, {
    [typeId]: {
      fields,
    },
  });
}

/**
 * @param {*} data the current module data
 * @param {*} type as a key to be find on state
 * @param {*} payload as a key to be find on state as setting type
 * @returns a listsSettings state according to the key or the initial state or an empty array if it's the first time accessing this function
 */
function getFieldsFromSettings(data, type, payload) {
  if (data[type][payload] && data[type][payload]?.fields) return data[type][payload].fields;
  return [];
}

export default {
  state: initialState(),
  getters: {
    getListFields: state => payload => getFieldsFromSettings(state, 'listsSettings', payload),
    getEntityFields: state => payload => getFieldsFromSettings(state, 'entitySettings', payload),
  },
  mutations: {
    SET_LIST_FIELDS(state, payload) {
      setSettingsValue(state.listsSettings, {
        typeId: payload.typeId,
        fields: payload.fields,
      });
    },
    SET_ENTITY_FIELDS(state, payload) {
      setSettingsValue(state.entitySettings, {
        typeId: payload.typeId,
        fields: payload.fields,
      });
    },
  },
  namespaced: true,
};

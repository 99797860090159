/*
  User methods
 */

import vars from '@/config/vars';

export function mergeUserSelections(selections = []) {
  const res = selections.reduce((acum, curr) => {
    if (_.isEmpty(acum)) {
      return curr;
    }

    const filter = _.cloneDeep(acum);

    Object.entries(curr).forEach(([key, value]) => {
      const previousValue = filter[key];
      const currentValue = curr[key];

      if (Array.isArray(value)) {
        const unionValues = _.union(value, previousValue);

        switch (key) {
          case 'countries':
            filter[key] = unionValues;
            break;
          default:
            filter[key] = value?.length === 0 || previousValue?.length === 0 ? [] : unionValues;
        }
      } else if (key === 'value_min') {
        const previousValueMin = previousValue || 0;
        const currentValueMin = currentValue || 0;

        filter[key] = currentValueMin < previousValueMin ? currentValueMin : previousValueMin;
      } else if (key === 'value_max') {
        const previousValueMax = previousValue || vars.CONST_MAX_BUDGET_LIMIT;
        const currentValueMax = currentValue || vars.CONST_MAX_BUDGET_LIMIT;

        filter[key] = currentValueMax > previousValueMax ? currentValueMax : previousValueMax;
      } else {
        filter[key] = currentValue;
      }
    });

    return filter;
  }, {});

  return res;
}

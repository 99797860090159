<template>
  <base-modal
    ref="modal"
    class="dead-message-subscribe-modal"
    modal-class="modal--form"
    :title="$vDict('dead_message_subscriptions.create_subscription_title.text')"
    :ok-title="$vDict('dead_message_subscriptions.create_subscription_button.text')"
    :is-loading-ok="isLoadingSubmit"
    @ok="submitSubscribe"
    @shown="onShownModal"
  >
    <dead-message-subscription-form
      ref="form"
    />
  </base-modal>
</template>

<script>
import MixinModal from "@/mixins/MixinModal";
import BaseModal from "@/components/modals/BaseModal";
import DeadMessageSubscriptionForm from "@/views/system/dead-message-subscriptions/DeadMessageSubscriptionForm";
import { postDeadMessageSubscription } from "@/api/repositories/dictionariesRepository";

export default {
  name: 'dead-message-subscribe-modal',
  components: {
    BaseModal,
    DeadMessageSubscriptionForm,
  },
  mixins: [
    MixinModal,
  ],
  data() {
    return {
      isLoadingSubmit: false,
      formData: {},
    };
  },
  methods: {
    show(data = {}) {
      this.formData = {
        ...data,
        email: this.$store.getters['Account/getSettingsValue']('user.email'),
      };
      this.$refs.modal.show();
    },
    onShownModal() {
      this.$refs.form.patchFormData(this.formData);
    },
    async submitSubscribe(e) {
      e.preventDefault();

      const { form } = this.$refs;
      const isValid = await form.validateForm({
        toastErrors: false,
      });

      if (!isValid) {
        return;
      }

      const formData = form.getFormData();

      this.isLoadingSubmit = true;

      try {
        const res = await postDeadMessageSubscription({
          email: formData.email,
          topic: formData.topic,
          service_name: formData.service_name,
        });

        if (res) {
          this.$notifToastr('success', this.$vDict('dead_message_subscriptions.subscribed_successfully_text.text'));
          this.$refs.modal.hide();
          this.$emit('success');
        }
      } catch (e) {
        if (e.code === enums.SERVER_ERROR_CODES.ALREADY_EXISTS) {
          this.$notifToastr('error', this.$vDict('dead_message_subscriptions.error_subscription_already_exists.text'));
        } else {
          this.$notifUnexpectedError();
        }
      }

      this.isLoadingSubmit = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

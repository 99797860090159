import _union from "lodash/union";
import { getCountryModules } from "@/entities/country-modules/api";
import { getModules } from "@/api/repositories/systemRepository";

export async function loadCountriesComponents(countries = []) {
  const countryModulesResponse = await getCountryModules({
    filter: {
      country_id: {
        in: countries,
      },
    },
  });
  const countryModulesList = countryModulesResponse?.data || [];
  const modulesIds = countryModulesList.reduce((acum, countryModules) => {
    const modules = countryModules.modules || [];
    return _union(acum, modules);
  }, []);
  const modulesResponse = await getModules({
    filter: {
      id: {
        in: modulesIds,
      },
    },
  });
  const allModules = modulesResponse?.data || [];

  return countryModulesList.reduce((acum, countryModules) => {
    const modules = allModules.filter(module => countryModules.modules.includes(module.id));
    return [
      ...acum,
      {
        country_id: countryModules.country_id,
        components: modules.reduce((acum, module) => {
          const moduleComponents = module.components.map(el => el.name);
          return _union(acum, moduleComponents);
        }, []),
      },
    ];
  }, []);
}

import enums from '@/config/enums';
import router from '@/router';
import store from '@/store';
import {
  isEmptyValue,
  rmEmptyParams,
  convertOffsetsToDateRange,
  convertWeekRangeToDateRange,
} from '@/shared/utils';
import {
  queryFields,
  queryFilterTypes,
  filterRangeTypes,
  filterArrayTypes,
} from '@/config/api';
import { CookiesStorage } from "@/shared/storage/drivers/cookiesStorage";

const { DateTime } = require('luxon');

function arrayToString(array) {
  return array.join(',');
}

function subFilterObjectItem(value) {
  let res = value;

  if (Array.isArray(value)) {
    const values = value.map(val => typeof val === 'string' ? `'${val}'` : val);
    res = `[${values.join(',')}]`;
  }

  return res;
}

function subFilterObject(param) {
  return Object.entries(param).map(([paramKey, paramValue]) => (queryFilterTypes.includes(paramKey) ?
    `${paramKey}:${subFilterObjectItem(paramValue)}` :
    subFilterObjectItem(paramValue)));
}

function paramValueObjectToString(paramKey, paramValue) {
  const pvKeys = Object.keys(paramValue);
  let res = '';

  if (pvKeys.length > 1) {
    const strArray = pvKeys.map(key => subFilterObject({
      [key]: paramValue[key],
    }));

    if (pvKeys.length === 2 && (_.difference(pvKeys, filterArrayTypes).length || _.difference(pvKeys, filterRangeTypes).length)) {
      res = `${paramKey}:${strArray.join(`,${paramKey}:`)}`;
    } else {
      res = `${paramKey}:${strArray.join(':')}`;
    }
  } else {
    res = `${paramKey}:${subFilterObject(paramValue)}`;
  }

  return res;
}

function paramObjectToString(param) {
  const strArray = Object.entries(param).map(([paramKey, paramValue]) => {
    let string = '';

    if (Array.isArray(paramValue)) {
      string = `${paramKey}:${arrayToString(paramValue)}`;
    } else if (typeof paramValue === 'object') {
      string = paramValueObjectToString(paramKey, paramValue);
    } else {
      string = `${paramKey}:${paramValue}`;
    }

    return string;
  });

  return strArray.join(',');
}

function getFilterInNotIn(filterValue) {
  const value = Array.isArray(filterValue) ? filterValue : [];
  const res = {
    in: value.filter(number => number >= 0),
    not_in: value.filter(number => number < 0).map(number => -number),
  };

  return Object.entries(res).reduce((acum, [resKey, resValue]) => {
    return resValue.length > 0 ? { ...acum, [resKey]: resValue } : acum;
  }, {});
}

function getFilterInNotInAggString(filterValue = {}) {
  const value = Array.isArray(filterValue) ? filterValue : [];
  const res = {
    in: value.filter(el => el.values.every(val => typeof val !== 'number' || val >= 0)),
    not_in: value.filter(el => el.values.some(val => typeof val === 'number' && val < 0)),
  };

  return Object.entries(res).reduce((acum, [resKey, resValue]) => {
    return resValue.length > 0 ? {
      ...acum,
      [resKey]: resValue
        .map(el => el.values.map(val => (typeof val === 'number' && val < 0 ? Math.abs(val) : val)).join('.')),
    } : acum;
  }, {});
}

function getFilterGteLte(filterValue) {
  const value = Array.isArray(filterValue) ? {
    gte: filterValue[0],
    lte: filterValue[1],
  } : filterValue;
  const res = {};

  Object.keys(value).forEach(qKey => {
    let val = value[qKey];

    if (typeof val === 'string') {
      const matches = val.match(/\d+/g) || [];
      const strNumber = matches.join('');
      const number = parseInt(strNumber);

      // val = !isNaN(number) && number ? number : '';
      val = !isNaN(number) && typeof number === 'number' ? number : '';
    }

    if (!isEmptyValue(val)) {
      res[qKey] = val;
    }
  });

  return res;
}

function convertDateRangeToQuery(dateRange = {}, isAddTimeForced = false) {
  const res = {};

  ['gte', 'lte'].forEach(qKey => {
    if (dateRange[qKey]) {
      let date = dateRange[qKey]?.isLuxonDateTime ? dateRange[qKey] : DateTime.fromSQL(dateRange[qKey], { zone: 'utc' });

      if (isAddTimeForced && qKey === 'lte') {
        date = date.plus({ days: 1 });
      }

      res[qKey] = date.toISODate();

      if (isAddTimeForced) {
        res[qKey] += ' 00:00';
      }
    }
  });

  return res;
}

function convertDateTypeToDateRange(data = {}, isAddTimeForced = false) {
  const { value, type } = data;
  let res;

  switch (type) {
    case 'gte_now':
      res = {
        gte: DateTime.utc().toFormat(vars.LUXON_API_DATE_TIME),
      };
      break;
    case 'lte_now':
      res = {
        lte: DateTime.utc().toFormat(vars.LUXON_API_DATE_TIME),
      };
      break;
    case 'custom':
      res = convertDateRangeToQuery(value, isAddTimeForced);
      break;
    default:
  }

  return res;
}

function getDateTimeQueryValue(value, isAddTimeForced = false) {
  let res = value;

  if (!isEmptyValue(value?.gteOffsetWeeks) || !isEmptyValue(value?.lteOffsetWeeks)) {
    res = convertWeekRangeToDateRange(value);
  } else if (!isEmptyValue(value?.gteOffsetDays) || !isEmptyValue(value?.lteOffsetDays)) {
    res = convertOffsetsToDateRange(value);
  } else if (!isEmptyValue(value?.gteOffsetMonths) || !isEmptyValue(value?.lteOffsetMonths)) {
    res = {
      gte: !isEmptyValue(value.gteOffsetMonths) ? DateTime.now().plus({ months: value.gteOffsetMonths }).startOf('month') : null,
      lte: !isEmptyValue(value.lteOffsetMonths) ? DateTime.now().plus({ months: value.lteOffsetMonths }).endOf('month') : null,
    };
  }

  if (!isEmptyValue(value?.type)) {
    res = convertDateTypeToDateRange(value);
    return res;
  }

  return convertDateRangeToQuery(res, isAddTimeForced);
}

function jsFilterToQueryFilter(filterData = {}, endpointFilter = {}) {
  const filterEntries = Object.entries(filterData)
    .filter(([filterKey, filterValue]) => !isEmptyValue(filterValue));
  const queryFilter = {};

  // console.log('jsFilterToQueryFilter', filterData);

  filterEntries.forEach(([filterKey, filterValue]) => {
    const filterSetting = endpointFilter[filterKey] || {};
    const filterType = filterSetting.type;
    let res = {};
    let value = null;

    switch (filterType) {
      case enums.API_FILTER_TYPES.EQ_BOOLEAN_NUMBER:
        if (filterValue !== null && filterValue !== -1) {
          res = {
            eq: filterValue ? 1 : 0,
          };
        }
        break;
      case enums.API_FILTER_TYPES.EQ_BOOLEAN:
        res = {
          eq: !!filterValue,
        };
        break;
      case enums.API_FILTER_TYPES.EQ_INT:
      case enums.API_FILTER_TYPES.EQ_STRING:
        if (typeof filterValue !== 'object') {
          res = {
            eq: filterValue,
          };
        }
        break;
      case enums.API_FILTER_TYPES.IN_INT:
      case enums.API_FILTER_TYPES.IN_STRING:
        res = {
          in: Array.isArray(filterValue) ? filterValue : [filterValue],
        };
        break;
      case enums.API_FILTER_TYPES.NOT_IN_INT:
        res = {
          not_in: filterValue,
        };
        break;
      case enums.API_FILTER_TYPES.Q_STRING:
        res = {
          q: filterValue,
        };
        break;
      case enums.API_FILTER_TYPES.GTE_LTE_FLOAT:
      case enums.API_FILTER_TYPES.GTE_LTE_INT:
        res = getFilterGteLte(filterValue);
        break;
      case enums.API_FILTER_TYPES.IN_NOT_IN_INT:
        value = getFilterInNotIn(filterValue);
        Object.keys(value).forEach(qKey => {
          queryFilter[_.has(queryFilter, filterKey) ? `~${filterKey}` : filterKey] = {
            [qKey]: value[qKey],
          };
        });
        break;
      case enums.API_FILTER_TYPES.DATE:
        value = filterValue ? DateTime(filterValue) : null;
        if (value && value.isValid) {
          res = {
            eq: value.toISODate(),
          };
        }
        break;
      case enums.API_FILTER_TYPES.DATE_TIME:
        value = filterValue ? DateTime(filterValue) : null;
        if (value && value.isValid) {
          res = {
            eq: value.toISO(),
          };
        }
        break;
      case enums.API_FILTER_TYPES.GTE_LTE_DATE:
      case enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME:
        res = getDateTimeQueryValue(filterValue, filterType === enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME);
        break;
      case enums.API_FILTER_TYPES.GTE_LTE_RANGES:
        value = [];

        filterValue.forEach(el => {
          const rangeVal = getFilterGteLte(el);
          const val = [rangeVal?.gte, rangeVal?.lte]
            .filter(rVal => !isEmptyValue(rVal)).join('_');

          if (val) {
            value.push(val);
          }
        });

        if (value.length > 0) {
          queryFilter[`${filterKey}|ranges`] = {
            in: value,
          };
        }
        break;
      case enums.API_FILTER_TYPES.IN_NOT_IN_AGG_STRING:
        value = getFilterInNotInAggString(filterValue);
        Object.keys(value).forEach(qKey => {
          queryFilter[_.has(queryFilter, filterKey) ? `~${filterKey}` : filterKey] = {
            [qKey]: value[qKey],
          };
        });
        break;
      default:
    }

    if (!_.isEmpty(res)) {
      queryFilter[filterKey] = res;
    }
  });

  return queryFilter;
}

function prepareQueryObject(endpoint = {}, query = {}) {
  const {
    filter: queryFilter,
    ...restQuery
  } = query;
  const res = {
    filter: queryFilter || {},
  };

  Object.entries(restQuery).forEach(([key, param]) => {
    if (key === 'jsFilter') {
      const queryFilter = jsFilterToQueryFilter(param, endpoint.filter);
      const searchStr = param.keywords || param.search;

      Object.assign(res.filter, queryFilter);

      if (endpoint.queryFilter) {
        // console.log('!!!jsFilterToQueryFilter queryFilter');
        const queryParamsFilter = jsFilterToQueryFilter(param, endpoint.queryFilter);
        const resQueryParams = Object.entries(queryParamsFilter).reduce((acum, [filterKey, filterValue]) => {
          const isValueObject = filterValue && !Array.isArray(filterValue) && typeof filterValue === 'object';
          const values = isValueObject ? Object.values(filterValue) : [filterValue];
          return {
            ...acum,
            [filterKey]: values.length > 1 ? filterValue : values[0],
          };
        }, {});

        // console.log('!!!!!!jsFilterToQueryFilter queryFilter res', queryParamsFilter, resQueryParams);

        Object.assign(res, resQueryParams);

        // console.log('###jsFilterToQueryFilter queryFilter res', res);
      }

      if (searchStr) {
        res.search = searchStr;
      }
    } else {
      res[key] = param;
    }

    // console.log('2###jsFilterToQueryFilter queryFilter res', res);
  });

  return rmEmptyParams(res);
}

export function convertQueryToGetParams(endpoint = {}, query = {}) {
  const queryObject = prepareQueryObject(endpoint, query);
  const resQuery = {};

  Object.entries(queryObject).forEach(([key, param]) => {
    let queryString = '';

    if (Array.isArray(param)) {
      queryString = arrayToString(param);
    } else if (typeof param === 'object') {
      queryString = paramObjectToString(param);
    } else {
      queryString = param;
    }

    if (key === 'search') {
      resQuery.q = queryString;
    } else {
      resQuery[key] = queryString;
    }
  });

  return resQuery;
}

export function convertQueryToJsonRpc(endpoint = {}, query = {}) {
  const queryObject = prepareQueryObject(endpoint, query);
  const fieldsNumberKeys = ['limit', 'offset'];
  const resQuery = {};

  Object.entries(queryObject).forEach(([key, value]) => {
    const val = fieldsNumberKeys.includes(key) ? Number(value) : value;

    if (queryFields.includes(key)) {
      resQuery.query = Object.assign({}, resQuery.query, { [key]: val });
    } else {
      resQuery[key] = val;
    }
  });

  return resQuery;
}

export function handlerLoadItemError(error) {
  switch (error.code) {
    case enums.SERVER_ERROR_CODES.NOT_FOUND:
    case enums.SERVER_ERROR_CODES.INVALID_USERNAME: // temp for entity pages (not found)
    case enums.SERVER_ERROR_CODES.FORBIDDEN:
      router.push({
        name: 'Page404',
      });
      break;
    // case enums.SERVER_ERROR_CODES.INTERNAL_SERVER_ERROR:
    //   router.push({
    //     name: 'Page500',
    //   });
    //   break;
    default:
      router.push({
        name: 'Page500',
      });
  }
}

export function getFileDownloadLink(fileId, section = 'files', accessToken, countryId) {
  const opid = CookiesStorage.get('OPID');

  if (!accessToken && !opid) {
    return;
  }

  const linkCountryId = countryId || store.getters['Account/getSettingsValue']('client.country_id');
  let link = `${process.env.VUE_APP_API_BASE_URL}/v1/${section}/download?file_id=${fileId}`;

  if (accessToken) {
    link += `&access_token=${accessToken}`;
  } else if (opid) {
    link += `&opid=${opid}`;
  }

  if (linkCountryId) {
    link += `&country_id=${linkCountryId}`;
  }

  return link;
}

import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

// Api clients
const SupportApiClients = () => import(/* webpackChunkName: "support-api-clients" */ '@/views/support/api-clients/ListApiClients');
const SupportApiClient = () => import(/* webpackChunkName: "support-api-client" */ '@/views/support/api-clients/ApiClient');
const SupportEditorApiClient = () => import(/* webpackChunkName: "support-api-client-editor" */ '@/views/support/api-clients/EditorApiClient');

// Webhooks
const SupportWebhooks = () => import(/* webpackChunkName: "support-webhooks" */ '@/views/support/webhooks/Webhooks');
const SupportEditorWebhook = () => import(/* webpackChunkName: "support-webhook-editor" */ '@/views/support/webhooks/EditorWebhook');
const SupportWebhook = () => import(/* webpackChunkName: "support-webhook" */ '@/views/support/webhooks/Webhook');
const SupportWebhookQueue = () => import(/* webpackChunkName: "support-webhook-queue" */ '@/views/support/webhooks/WebhookQueue');

// User Webhooks
const SettingsWebhooks = () => import(/* webpackChunkName: "settings-webhooks" */ '@/views/settings/webhooks/Webhooks');
const SettingsEditorWebhook = () => import(/* webpackChunkName: "settings-webhook-editor" */ '@/views/settings/webhooks/EditorWebhook');
const SettingsWebhook = () => import(/* webpackChunkName: "settings-webhook" */ '@/views/settings/webhooks/Webhook');
const UserWebhookQueue = () => import(/* webpackChunkName: "user-webhook-queue" */ '@/views/settings/webhooks/UserWebhookQueue');

export default [
  {
    meta: {
      breadcrumb: { name: 'api-settings' },
      dictKey: 'DashboardContainer',
      menuId: 40,
    },
    path: '/api-settings',
    redirect: '/api-settings/api-clients',
    name: 'ApiSettings',
    component: DashboardContainer,
    children: [
      {
        path: 'api-clients',
        redirect: '/api-settings/api-clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'api_clients',
              middleware: [validateComponent],
              breadcrumb: { name: 'Api Clients' },
              dictKey: 'ApiClients',
              menuId: 43,
            },
            path: '/api-settings/api-clients',
            name: 'SupportApiClients',
            component: SupportApiClients,
          },
          {
            meta: {
              breadcrumb: { name: 'Create api client' },
              component: 'api_clients',
              middleware: [validateComponent],
              dictKey: 'ApiClients',
              parentMenuId: 43,
            },
            path: 'new',
            name: 'SupportEditorApiClientNew',
            component: SupportEditorApiClient,
          },
          {
            meta: {
              breadcrumb: { name: 'Api client', dynamic: true },
              component: 'all_users',
              middleware: [validateComponent],
              dictKey: 'SupportApiClient',
              parentMenuId: 43,
            },
            path: ':id',
            name: 'SupportApiClient',
            component: SupportApiClient,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit api client', dynamic: true },
              component: 'api_clients',
              middleware: [validateComponent],
              dictKey: 'ApiClients',
              parentMenuId: 43,
            },
            path: ':id/edit',
            name: 'SupportEditorApiClient',
            component: SupportEditorApiClient,
          },
        ],
      },
      {
        path: 'webhooks',
        redirect: '/api-settings/webhooks',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'webhook_support',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Webhooks',
              },
              dictKey: 'SupportWebhook',
              menuId: 57,
            },
            path: '/api-settings/webhooks',
            name: 'SupportWebhooks',
            component: SupportWebhooks,
          },
          {
            meta: {
              breadcrumb: { name: 'Create webhook' },
              component: 'webhook_support',
              middleware: [validateComponent],
              dictKey: 'SupportWebhook',
              parentMenuId: 57,
            },
            path: 'new',
            name: 'SupportEditorWebhookNew',
            component: SupportEditorWebhook,
          },
          {
            meta: {
              breadcrumb: { name: 'Webhook', dynamic: true },
              component: 'webhook_support',
              middleware: [validateComponent],
              dictKey: 'SupportWebhook',
              parentMenuId: 57,
            },
            path: ':id',
            name: 'SupportWebhook',
            component: SupportWebhook,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit webhook', dynamic: true },
              component: 'webhook_support',
              middleware: [validateComponent],
              dictKey: 'SupportWebhook',
              parentMenuId: 57,
            },
            path: ':id/edit',
            name: 'SupportEditorWebhook',
            component: SupportEditorWebhook,
          },
        ],
      },
      {
        path: 'my-webhooks',
        redirect: '/api-settings/my-webhooks',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'webhook_list',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'My webhooks',
              },
              dictKey: 'SettingsWebhook',
              menuId: 55,
            },
            path: '/api-settings/my-webhooks',
            name: 'SettingsWebhooks',
            component: SettingsWebhooks,
          },
          {
            meta: {
              breadcrumb: { name: 'Create webhook' },
              component: 'webhook_editor',
              middleware: [validateComponent],
              dictKey: 'SettingsWebhook',
              parentMenuId: 55,
            },
            path: 'new',
            name: 'SettingsEditorWebhookNew',
            component: SettingsEditorWebhook,
          },
          {
            meta: {
              breadcrumb: { name: 'Webhook', dynamic: true },
              component: 'webhook_view',
              middleware: [validateComponent],
              dictKey: 'SettingsWebhook',
              parentMenuId: 55,
            },
            path: ':id',
            name: 'SettingsWebhook',
            component: SettingsWebhook,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit webhook', dynamic: true },
              component: 'webhook_editor',
              middleware: [validateComponent],
              dictKey: 'SettingsWebhook',
              parentMenuId: 55,
            },
            path: ':id/edit',
            name: 'SettingsEditorWebhook',
            component: SettingsEditorWebhook,
          },
        ],
      },
      {
        meta: {
          component: 'webhook_queue_list',
          middleware: [validateComponent],
          breadcrumb: { name: 'Webhook queue' },
          dictKey: 'SupportWebhookQueue',
          menuId: 58,
        },
        path: '/api-settings/webhook-queue',
        name: 'SupportWebhookQueue',
        component: SupportWebhookQueue,
      },
      {
        meta: {
          component: 'user_webhook_queue_list',
          middleware: [validateComponent],
          breadcrumb: { name: 'My webhook queue' },
          dictKey: 'UserWebhookQueue',
          menuId: 72,
        },
        path: '/api-settings/my-webhook-queue',
        name: 'UserWebhookQueue',
        component: UserWebhookQueue,
      },
    ],
  },
];

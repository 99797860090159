import Vue from "vue";
import { getClientUsers } from "@/api/repositories/clientsRepository";
import { multipleLoadItems } from "@/shared/utils";
import { IndexedArray } from "@/shared/proxies";
import { DateTime } from "luxon";

const initialState = () => ({
  users: [],
  isUsersLoaded: false,
});

export default {
  state: initialState(),
  getters: {
    users(state, getters, rootState, rootGetters) {
      const userData = rootGetters['Account/userMainData'];

      return new IndexedArray([
        {
          ...userData,
          me_name: Vue.prototype.$vDict('global.text_user_me.text', { name: userData.name }),
          firstInList: true,
          isCurrentUser: true,
        },
        ...state.users,
      ]);
    },
    getUser: (state, getters) => (userId) => {
      return getters.users.findById(userId);
    },
    getUnknownUserIds: (state, getters) => (userIds = []) => {
      return userIds.filter(userId => !getters.users.findById(userId));
    },
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload.users;
      state.isUsersLoaded = true;
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
  },
  actions: {
    async fetchUsers({ state, commit, getters, rootGetters }, payload = {}) {
      if (state.isUsersLoaded) {
        return getters.users;
      }

      const userSubscriptions = rootGetters['Account/activeSubscriptions'];

      try {
        const clientUsers = await multipleLoadItems((offset, limit) => {
          return getClientUsers({
            sort: {
              'user.name': { order: 'a' },
            },
            filter: {
              'client.id': {
                eq: rootGetters['Account/getSettingsValue']('client.id'),
              },
              'user.is_suspended': {
                eq: false,
              },
              'subscription.end_date': {
                gte: DateTime.utc().toFormat(vars.LUXON_API_DATE_TIME),
              },
              'user_profile.is_suspended': {
                eq: false,
              },
              'subscription.id': {
                in: userSubscriptions.map(subscription => subscription.id),
              },
            },
            subscriptions: 1,
            offset,
            limit,
          });
        }, Number(process.env.VUE_APP_MAX_LIMIT));

        const users = clientUsers
          .map(clientUserData => {
            const { user } = clientUserData;

            return {
              id: user.id,
              name: user.name,
              email: user.email,
              pic: user.pic,
            };
          })
          .filter(el => el.id !== rootGetters['Account/userId']);

        commit('SET_USERS', { users });
      } catch (err) {}

      return getters.users;
    }
  },
  namespaced: true,
};

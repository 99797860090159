import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

const ClientVersions = () => import(/* webpackChunkName: "myran-logs-client-versions" */ '@/views/myran-logs/ClientVersions');
const SubscriptionVersions = () => import(/* webpackChunkName: "myran-logs-subscription-versions" */ '@/views/myran-logs/SubscriptionVersions');
const SuspendedUsers = () => import(/* webpackChunkName: "myran-logs-suspended-users" */ '@/views/myran-logs/SuspendedUsers');
const UserProfiles = () => import(/* webpackChunkName: "myran-logs-user-profiles" */ '@/views/myran-logs/UserProfiles');
const UserVersions = () => import(/* webpackChunkName: "myran-logs-user-versions" */ '@/views/myran-logs/UserVersions');

export default [
  {
    meta: {
      breadcrumb: { name: 'myran_logs' },
      dictKey: 'DashboardContainer',
      menuId: 44,
    },
    path: '/myran-logs',
    redirect: '/myran-logs/client-versions',
    name: 'MyranLogs',
    component: DashboardContainer,
    children: [
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'Client versions' },
          menuId: 46,
        },
        path: '/myran-logs/client-versions',
        name: 'ClientVersions',
        component: ClientVersions,
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'Subscription versions' },
          menuId: 47,
        },
        path: '/myran-logs/subscription-versions',
        name: 'SubscriptionVersions',
        component: SubscriptionVersions,
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'Deactivated users' },
          menuId: 49,
        },
        path: '/myran-logs/suspended-users',
        name: 'SuspendedUsers',
        component: SuspendedUsers,
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'User profiles' },
          dictKey: 'MyranLogsUserProfiles',
          menuId: 48,
        },
        path: '/myran-logs/user-profiles',
        name: 'UserProfiles',
        component: UserProfiles,
      },
      {
        meta: {
          component: 'myran_logs',
          middleware: [validateComponent],
          breadcrumb: { name: 'User versions' },
          menuId: 45,
        },
        path: '/myran-logs/user-versions',
        name: 'UserVersions',
        component: UserVersions,
      },
    ],
  },
];

import { isAuthorized } from "@/router/utils";
import { parseIntFromString } from "@/shared/utils";
import store from "@/store";

export function checkSetClientId(next, to) {
  const isAuthPage = to.meta.auth || to.query.hasOwnProperty('token');
  const shouldSetClientId = !isAuthPage &&
    isAuthorized() &&
    to.query.set_client_id;

  if (!shouldSetClientId) {
    return false;
  }

  const setClientId = parseIntFromString(to.query.set_client_id);
  const currentClientId = store.getters['Account/getSettingsValue']('client.id');

  if (currentClientId === setClientId) {
    return false;
  }

  const liveClients = store.getters['Account/liveClients'];
  const hasAccessToClient = liveClients.some(client => client.id === setClientId);

  if (!hasAccessToClient) {
    next({
      name: 'ClientAccessDenied',
      query: {
        clientId: setClientId,
      },
    });
    return true;
  }

  const {
    set_client_id,
    ...queryParams
  } = to.query;

  next({
    name: 'ReissueToken',
    query: {
      clientId: setClientId,
      destination: encodeURI(`${to.path}?${new URLSearchParams(queryParams).toString()}`),
    },
  });
  return true;
}

import Vue from 'vue';
import { getDomainCountryId } from "@/config/domains";
import { logoByCountry } from "@/config/logo";
import { themes } from '@/config/themes';

const WebFont = require('webfontloader');

const defaultTheme = 'main';
const defaultLogo = {
  fileName: 'logo-default',
  alt: 'Smart',
};
const defaultLogoPath = '/img/brand/';
const defaultLogoExtension = '.svg';
const defaultFonts = [
  'Roboto+Condensed:300,400,700',
  'Roboto:400,500,700&display=swap&subset=latin-ext'
];
// const defaultFonts = 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:400,500,700&display=swap&subset=latin-ext';


class AppTheme {
  constructor () {
    this.storeVM = new Vue({
      data: {
        defaultThemeKey: localStorage.getItem('THEME') || defaultTheme,
        currentTheme: null,
        currentLogo: defaultLogo,
        loadingTheme: false,
      },
    });
  }

  get state () {
    return this.storeVM.$data;
  }

  getCalculateThemeKey() {
    const { hostname } = window.location;
    let res = this.state.defaultThemeKey;

    if (hostname.indexOf('olmero.ch') !== -1) {
      res = 'olmero';
    }

    return res;
  }

  initTheme(themeKey) {
    const currentThemeKey = themeKey || this.getCalculateThemeKey();

    this.setTheme(currentThemeKey);
    this.initLogo();
    this.initFonts();
    this.initFavicon();
  }

  setTheme(themeKey) {
    const currentThemeKey = themeKey || this.state.defaultThemeKey;
    const currentTheme = themes.find(el => el.key === currentThemeKey);

    if (!currentTheme) {
      return;
    }

    const head = document.querySelector('head');
    const themeLink = document.querySelector('#theme');
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = `${currentTheme.css}?v=${process.env.PACKAGE_VERSION}`;
    link.id = 'theme';

    this.state.loadingTheme = true;

    if (themeLink) {
      head.replaceChild(link, themeLink);
    } else {
      head.appendChild(link);
    }

    link.onload = () => {
      this.state.loadingTheme = false;
      localStorage.setItem('THEME', this.state.currentTheme.key);
    };

    this.state.currentTheme = _.cloneDeep(currentTheme);

    // add class to html
    const html = document.documentElement;

    themes.forEach(theme => {
      html.classList.remove(`app-theme-${theme.key}`);
    });
    html.classList.add(`app-theme-${this.state.currentTheme.key}`);
  }

  initLogo() {
    const themeLogo = this.state.currentTheme?.logo;

    if (themeLogo) {
      this.state.currentLogo = _.cloneDeep(themeLogo);
      return;
    }

    const countryId = getDomainCountryId();
    this.state.currentLogo = _.cloneDeep(logoByCountry[countryId] || defaultLogo);
  }

  initFonts() {
    const fontFamilies = this.state.currentTheme?.fonts || defaultFonts;

    WebFont.load({
      google: {
        families: fontFamilies,
      },
    });

    // const fontsLink = document.querySelector('#fonts');
    //
    // if (!fontsLink) {
    //   return;
    // }

    // fontsLink.href = fontsUrl;
  }

  setFavicon(favId, favHref) {
    if (!favHref) {
      return;
    }

    const faviconLink = document.querySelector(favId);

    if (faviconLink) {
      faviconLink.href = favHref;
    }
  }

  initFavicon() {
    this.setFavicon('#favicon16', this.state.currentTheme?.favicon16);
    this.setFavicon('#favicon32', this.state.currentTheme?.favicon32);
  }

  getLogo(_logoSuffix) {
    const logoSuffix = _logoSuffix || this.state.currentTheme?.logoSuffix;
    const logoSrc = this.state.currentLogo.fileName ? [
        defaultLogoPath,
        this.state.currentLogo.fileName,
        (logoSuffix ? `-${logoSuffix}` : ''),
        this.state.currentTheme?.logo?.extension || defaultLogoExtension,
      ].join('') : '';

    return {
      ...this.state.currentLogo,
      src: logoSrc,
    };
  }
}

export default {
  install(Vue, {store}) {
    const appTheme = new AppTheme({ Vue, store });

    // init theme
    appTheme.initTheme();

    Vue.mixin({
      beforeCreate () {
        this.$getLogo = (_logoSuffix) => {
          return appTheme.getLogo(_logoSuffix);
        };

        this.$changeAppTheme = (themeKey) => {
          appTheme.initTheme(themeKey);
        };
      }
    });
  }
}

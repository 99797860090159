import validateComponent from "@/router/middleware/validateComponent";
import {
  DashboardContainer,
} from "@/router/components";

const Contacts = () => import(/* webpackChunkName: "vortal-connect-contacts" */ '@/views/vortal-connect/Contacts');
const Companies = () => import(/* webpackChunkName: "vortal-connect-companies" */ '@/views/vortal-connect/Companies');
const Projects = () => import(/* webpackChunkName: "vortal-connect-projects" */ '@/views/vortal-connect/Projects');
const NotificationEmails = () => import(/* webpackChunkName: "vortal-connect-notif-emails" */ '@/views/vortal-connect/NotificationEmails');
const Logs = () => import(/* webpackChunkName: "vortal-connect-logs" */ '@/views/vortal-connect/Logs');
const EditorContact = () => import(/* webpackChunkName: "vortal-connect-contact-editor" */ '@/views/vortal-connect/EditorContact');
const EditorCompany = () => import(/* webpackChunkName: "vortal-connect-company-editor" */ '@/views/vortal-connect/EditorCompany');
const EditorNotificationEmail = () => import(/* webpackChunkName: "vortal-connect-notif-email-editor" */ '@/views/vortal-connect/EditorNotificationEmail');

export default [
  {
    path: '/vortal-connect',
    redirect: '/vortal-connect/contacts',
    name: 'VortalConnect',
    meta: {
      breadcrumb: { name: 'vortal_connect' },
      dictKey: 'DashboardContainer',
      menuId: 62,
    },
    component: DashboardContainer,
    children: [
      {
        path: 'contacts',
        redirect: '/vortal-connect/contacts',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/contacts',
            name: 'VortalConnectContacts',
            component: Contacts,
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectContacts',
              menuId: 63,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'Edit contact', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectContacts',
              parentMenuId: 63,
            },
            path: ':id/edit',
            name: 'VortalConnectEditorContact',
            component: EditorContact,
          },
        ],
      },
      {
        path: 'companies',
        redirect: '/vortal-connect/companies',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/companies',
            name: 'VortalConnectCompanies',
            component: Companies,
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectCompanies',
              menuId: 64,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'Edit company', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectCompanies',
              parentMenuId: 64,
            },
            path: ':id/edit',
            name: 'VortalConnectEditorCompany',
            component: EditorCompany,
          },
        ],
      },
      {
        path: 'projects',
        redirect: '/vortal-connect/projects',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/projects',
            name: 'VortalConnectProjects',
            component: Projects,
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectProjects',
              menuId: 65,
            },
          },
        ],
      },
      {
        path: 'notification-emails',
        redirect: '/vortal-connect/notification-emails',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/notification-emails',
            name: 'VortalConnectNotificationEmails',
            component: NotificationEmails,
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectNotificationEmails',
              menuId: 66,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'Edit notification email', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectNotificationEmails',
              parentMenuId: 66,
            },
            path: ':id/edit',
            name: 'VortalConnectEditorNotificationEmail',
            component: EditorNotificationEmail,
          },
          {
            meta: {
              breadcrumb: { name: 'New notification email', dynamic: true },
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectNotificationEmails',
              parentMenuId: 66,
            },
            path: 'new',
            name: 'VortalConnectEditorNotificationEmailNew',
            component: EditorNotificationEmail,
          },
        ],
      },
      {
        path: 'logs',
        redirect: '/vortal-connect/logs',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/vortal-connect/logs',
            name: 'VortalConnectLogs',
            component: Logs,
            meta: {
              component: 'vortal_support',
              middleware: [validateComponent],
              dictKey: 'VortalConnectLogs',
              menuId: 67,
            },
          },
        ],
      },
    ],
  }
];

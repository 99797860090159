import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

// Clients
const SupportClients = () => import(/* webpackChunkName: "support-clients" */ '@/views/support/clients/ListClients');
const SupportClient = () => import(/* webpackChunkName: "support-client" */ '@/views/support/clients/Client');
const SupportEditorClient = () => import(/* webpackChunkName: "support-client-editor" */ '@/views/support/clients/EditorClient');

// Users
const SupportUsers = () => import(/* webpackChunkName: "support-users" */ '@/views/support/users/Users');
const SupportUser = () => import(/* webpackChunkName: "support-user" */ '@/views/support/users/User');
const SupportEditorUser = () => import(/* webpackChunkName: "support-user-editor" */ '@/views/support/users/EditorUser');
const SupportClientUserEditor = () => import(/* webpackChunkName: "support-client-user-editor" */ '@/views/support/users/ClientUserEditor.vue');

// Files
const SupportFiles = () => import(/* webpackChunkName: "support-files" */ '@/views/support/files/Files');

// Release notes
const SupportEditorReleaseNote = () => import(/* webpackChunkName: "support-release-note-editor" */ '@/views/support/release-notes/EditorReleaseNote');
const SupportReleaseNotes = () => import(/* webpackChunkName: "support-release-notes" */ '@/views/support/release-notes/ListReleaseNotes');

// Subscriptions
const SupportSubscriptions = () => import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/SubscriptionsList.vue');
const SupportSubscription = () => import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/Subscription');
const SupportSubscriptionEdit = () => import(/* webpackChunkName: "support-release-notes" */ '@/views/support/subscriptions/SubscriptionEditor');

export default [
  {
    meta: {
      breadcrumb: { name: 'support' },
      dictKey: 'DashboardContainer',
      menuId: 40,
    },
    path: '/support',
    redirect: '/support/clients',
    name: 'Support',
    component: DashboardContainer,
    children: [
      {
        path: 'clients',
        redirect: '/support/clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'all_clients',
              middleware: [validateComponent],
              breadcrumb: { name: 'Organizations' },
              dictKey: 'SupportClients',
              menuId: 41,
            },
            path: '/support/clients',
            name: 'SupportClients',
            component: SupportClients,
          },
          {
            meta: {
              breadcrumb: { name: 'Create organization' },
              component: 'all_clients',
              middleware: [validateComponent],
              dictKey: 'SupportClient',
              parentMenuId: 41,
            },
            path: 'new',
            name: 'SupportEditorClientNew',
            component: SupportEditorClient,
          },
          {
            meta: {
              breadcrumb: { name: 'Organization', dynamic: true },
              component: 'all_clients',
              middleware: [validateComponent],
              dictKey: 'SupportClient',
              parentMenuId: 41,
            },
            path: ':id',
            name: 'SupportClient',
            component: SupportClient,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit organization', dynamic: true },
              component: 'all_clients',
              middleware: [validateComponent],
              dictKey: 'SupportEditorClient',
              parentMenuId: 41,
            },
            path: ':id/edit',
            name: 'SupportEditorClient',
            component: SupportEditorClient,
          },
        ],
      },
      {
        path: 'users',
        redirect: '/support/users',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'all_users',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Users',
              },
              dictKey: 'Users',
              menuId: 42,
            },
            path: '/support/users',
            name: 'SupportUsers',
            component: SupportUsers,
          },
          {
            meta: {
              breadcrumb: { name: 'Create user' },
              component: 'all_users',
              middleware: [validateComponent],
              dictKey: 'Users',
              parentMenuId: 42,
            },
            path: 'new',
            name: 'SupportEditorUserNew',
            component: SupportEditorUser,
          },
          {
            meta: {
              breadcrumb: { name: 'User', dynamic: true },
              component: 'all_users',
              middleware: [validateComponent],
              dictKey: 'SupportUser',
              parentMenuId: 42,
            },
            path: ':id',
            name: 'SupportUser',
            component: SupportUser,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit user', dynamic: true },
              component: 'all_users',
              middleware: [validateComponent],
              dictKey: 'Users',
              parentMenuId: 42,
            },
            path: ':id/edit',
            name: 'SupportEditorUser',
            component: SupportEditorUser,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit user organization', dynamic: true },
              component: 'all_users',
              middleware: [validateComponent],
              dictKey: 'SupportClientUserEditor',
              parentMenuId: 42,
            },
            path: ':id/edit-client/:clientId',
            name: 'SupportClientUserEditor',
            component: SupportClientUserEditor,
          },
        ],
      },
      {
        meta: {
          component: 'all_files',
          middleware: [validateComponent],
          dictKey: 'SupportFiles',
          menuId: 60,
        },
        path: '/support/files',
        name: 'SupportFiles',
        component: SupportFiles,
      },
      {
        path: 'release-notes',
        redirect: '/support/release-notes',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'release_notes',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Release notes',
              },
              dictKey: 'SupportReleaseNotes',
              menuId: 61,
            },
            path: '/support/release-notes',
            name: 'SupportReleaseNotes',
            component: SupportReleaseNotes,
          },
          {
            meta: {
              breadcrumb: { name: 'Create release note' },
              component: 'release_notes',
              middleware: [validateComponent],
              dictKey: 'SupportReleaseNote',
              parentMenuId: 61,
            },
            path: 'new',
            name: 'SupportEditorReleaseNoteNew',
            component: SupportEditorReleaseNote,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit release note', dynamic: true },
              component: 'release_notes',
              middleware: [validateComponent],
              dictKey: 'SupportReleaseNote',
              parentMenuId: 61,
            },
            path: ':id/edit',
            name: 'SupportEditorReleaseNote',
            component: SupportEditorReleaseNote,
          },
        ],
      },
      // {
      //   meta: {
      //     component: 'all_subscriptions',
      //     // middleware: [validateComponent],
      //     dictKey: 'SupportSubscriptions',
      //     menuId: 92,
      //   },
      //   path: '/support/subscriptions',
      //   name: 'SupportSubscriptions',
      //   component: SupportSubscriptions,

      // },
      {
        path: 'subscriptions',
        redirect: '/support/subscriptions',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'all_subscriptions',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'SupportSubscriptions',
              },
              dictKey: 'SupportSubscriptions',
              menuId: 92,
            },
            path: '/support/subscriptions',
            name: 'SupportSubscriptions',
            component: SupportSubscriptions,
          },
          {
            meta: {
              breadcrumb: { name: 'Create Subscription', dynamic: true },
              component: 'all_subscriptions',
              middleware: [validateComponent],
              dictKey: 'SupportSubscriptionEdit',
              parentMenuId: 92,
            },
            path: 'new',
            name: 'SupportSubscriptionCreate',
            component: SupportSubscriptionEdit,
          },
          {
            meta: {
              breadcrumb: { name: 'Subscription', dynamic: true },
              component: 'all_subscriptions',
              middleware: [validateComponent],
              dictKey: 'SupportSubscription',
              parentMenuId: 92,
            },
            path: ':id',
            name: 'SupportSubscription',
            component: SupportSubscription,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit Subscription', dynamic: true },
              component: 'all_subscriptions',
              middleware: [validateComponent],
              dictKey: 'SupportSubscriptionEdit',
              parentMenuId: 92,
            },
            path: ':id/edit',
            name: 'SupportSubscriptionEdit',
            component: SupportSubscriptionEdit,
          },
        ]
      }
    ],
  },
];

import LocalForage from 'localforage';
import _ from 'lodash';

export default class BaseStorage {
  dbPromise;

  constructor(instanceName, storeName) {
    this.dbPromise = new Promise(
      (resolve, reject) => {
        let db;

        const config = {
          name: instanceName,
          storeName,
        };

        try {
          db = LocalForage.createInstance(config);
          db.setDriver([LocalForage.INDEXEDDB, LocalForage.LOCALSTORAGE]);
          resolve(db);
        } catch (e) {
          console.log(e);
          reject(e);
        }
      },
    );
  }

  ready() {
    return this.dbPromise;
  }

  get(key) {
    return this.dbPromise.then(db => db.getItem(key));
  }

  set(key, value) {
    return this.dbPromise.then(db => db.setItem(key, value));
  }

  remove(key) {
    return this.dbPromise.then(db => db.removeItem(key));
  }

  clear() {
    return this.dbPromise.then(db => db.clear());
  }

  async patch(key, item) {
    const response = await this.get(key);

    if (response !== null) {
      this.set(key, Object.assign({}, response, item));
    } else {
      this.set(key, item);
    }

    return new Promise(resolve => resolve());
  }

  push(key, item) {
    return this.get(key)
      .then(response => {
        if (response !== null) {
          this.set(key, _.merge(response, item));
        } else {
          this.set(key, item);
        }
      });
  }

  keys() {
    return this.dbPromise.then(db => db.keys());
  }

  iterate() {
    return this.dbPromise.then(db => db.iterate());
  }
}

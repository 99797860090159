import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer, PrintContainer } from "@/router/components";
import {
  PrintProjects,
  PrintCompanies,
  PrintContacts,
  PrintTenders,
  PrintProject,
  PrintContact,
  PrintTender,
  PrintCompany,
  Project,
  Company,
  Contact,
  Tender,
} from "@/router/components";

const AllProjects = () => import(/* webpackChunkName: "all-projects" */ '@/views/leads/AllProjects');
const AllCompanies = () => import(/* webpackChunkName: "all-companies" */ '@/views/leads/AllCompanies');
const AllContacts = () => import(/* webpackChunkName: "all-contacts" */ '@/views/leads/AllContacts');
const AllTenders = () => import(/* webpackChunkName: "all-tenders" */ '@/views/leads/AllTenders');

export default [
  {
    path: '/leads/print',
    redirect: '/leads/projects/print',
    name: 'LeadsPrint',
    meta: {
      breadcrumb: { name: 'leads' },
      dictKey: 'PrintContainer',
    },
    component: PrintContainer,
    children: [
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'AllProjects',
        },
        path: '/leads/projects/print',
        name: 'PrintProjects',
        component: PrintProjects,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Project',
        },
        path: '/leads/projects/:id/print',
        name: 'PrintProject',
        component: PrintProject,
      },
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'AllCompanies',
        },
        path: '/leads/companies/print',
        name: 'PrintCompanies',
        component: PrintCompanies,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Company',
        },
        path: '/leads/companies/:id/print',
        name: 'PrintCompany',
        component: PrintCompany,
      },
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'MyContacts',
        },
        path: '/leads/contacts/print',
        name: 'PrintContacts',
        component: PrintContacts,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Contact',
        },
        path: '/leads/contacts/:id/print',
        name: 'PrintContact',
        component: PrintContact,
      },
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'AllTenders',
        },
        path: '/leads/tenders/print',
        name: 'PrintTenders',
        component: PrintTenders,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Tender',
        },
        path: '/leads/tenders/:id/print',
        name: 'PrintTender',
        component: PrintTender,
      },
    ],
  },
  {
    path: '/leads',
    redirect: '/leads/projects',
    name: 'Leads',
    meta: {
      breadcrumb: { name: 'leads' },
      dictKey: 'DashboardContainer',
      menuId: 8,
    },
    component: DashboardContainer,
    children: [
      {
        path: 'projects',
        redirect: '/leads/projects',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/leads/projects',
            name: 'Projects',
            component: AllProjects,
            meta: {
              component: 'all_projects',
              middleware: [validateComponent],
              breadcrumb: { name: 'all_projects' },
              dictKey: 'AllProjects',
              menuId: 9,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'project', dynamic: true },
              component: 'project',
              middleware: [validateComponent],
              dictKey: 'Project',
              parentMenuId: 9,
            },
            path: ':id',
            name: 'Project',
            component: Project,
          },
        ],
      },
      {
        path: 'companies',
        redirect: '/leads/companies',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/leads/companies',
            name: 'Companies',
            component: AllCompanies,
            meta: {
              component: 'all_companies',
              middleware: [validateComponent],
              breadcrumb: { name: 'all_companies' },
              dictKey: 'AllCompanies',
              menuId: 10,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'company', dynamic: true },
              component: 'company',
              middleware: [validateComponent],
              dictKey: 'Company',
              parentMenuId: 10,
            },
            path: ':id',
            name: 'Company',
            component: Company,
          },
        ],
      },
      {
        path: 'contacts',
        redirect: '/leads/contacts',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/leads/contacts',
            name: 'Contacts',
            component: AllContacts,
            meta: {
              component: 'all_contacts',
              middleware: [validateComponent],
              breadcrumb: { name: 'all_contacts' },
              dictKey: 'AllContacts',
              menuId: 11,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'person', dynamic: true },
              component: 'contact',
              middleware: [validateComponent],
              dictKey: 'Contact',
              parentMenuId: 11,
            },
            path: ':id',
            name: 'Contact',
            component: Contact,
          },
        ],
      },
      {
        path: 'tenders',
        redirect: '/leads/tenders',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/leads/tenders',
            name: 'Tenders',
            component: AllTenders,
            meta: {
              component: 'all_tenders',
              middleware: [validateComponent],
              breadcrumb: { name: 'all_tenders' },
              dictKey: 'AllTenders',
              menuId: 12,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'tender', dynamic: true },
              component: 'tender',
              middleware: [validateComponent],
              dictKey: 'Tender',
              parentMenuId: 12,
            },
            path: ':id',
            name: 'Tender',
            component: Tender,
          },
        ],
      },
    ],
  },
];

<script>
export default {
  computed: {
    urlParams() {
      return Object.entries(this.$route.query).reduce((acum, [key, value]) => ({
        ...acum,
        [key]: decodeURIComponent(value),
      }), {});
    },
  },
  watch: {
    $route(to, from) {
      // Implement your own logic directly in your parent component.
      // For taht, you only need to create the method onUrlChange where you imported this mixin
      if (this.onUrlChange && to.fullPath !== from.fullPath) {
        this.onUrlChange(to, from);
      }
    },
  },
  methods: {
    getUrlParam(key) {
      return this.urlParams[key] || null;
    },
    async setUrlParam(key, value) {
      const {
        [key]: urlValue,
        ...restQuery
      } = this.$route.query;

      if (value === urlValue) {
        return;
      }

      const query = value ? {
        ...restQuery,
        [key]: value,
      } : restQuery;

      await this.$router.replace({
        query,
      });
    },
  },
};
</script>

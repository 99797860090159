export function checkNetworkError(next, to) {
  if (navigator.onLine) {
    return false;
  }

  const routeName = 'PageNetworkError';

  if (to.name !== routeName) {
    next({
      name: routeName,
      query: {
        destination: encodeURI(to.fullPath),
      },
    });
  } else {
    next();
  }

  return true;
}

import Vue from 'vue';
import { hasPageBlockers } from "@/shared/dom";

function checkPageReady(func) {
  const timeoutId = setTimeout(() => {
    if (hasPageBlockers()) {
      checkPageReady(func);
    } else {
      func();
      clearTimeout(timeoutId);
    }
  }, 500);
}

export default {
  state: {
    loadings: {},
    errors: {},
    pageNotBlocked: false,
  },
  getters: {
    dlhIsLoading: state => (type) => {
      let res = false;

      if (type) {
        res = state.loadings[type] || false;
      } else {
        res = Object.values(state.loadings).some(isLoading => isLoading);
      }

      return res;
    },
    dlhIsError: state => (type) => {
      let res = false;

      if (type) {
        res = state.errors[type] || false;
      } else {
        res = Object.values(state.errors).some(isLoading => isLoading);
      }

      return res;
    },
    pageNotBlocked(state) {
      return state.pageNotBlocked;
    },
  },
  mutations: {
    DLH_RESET(state, payload = {}) {
      if (payload.type) {
        Vue.delete(state.loadings, payload.type);
        Vue.delete(state.errors, payload.type);
      } else {
        state.loadings = {};
        state.errors = {};
      }

      state.pageNotBlocked = false;
    },
    DLH_LOAD(state, payload = {}) {
      if (payload.type) {
        Vue.set(state.loadings, payload.type, true);
        Vue.delete(state.errors, payload.type);

        state.pageNotBlocked = false;
      }
    },
    DLH_SUCCESS(state, payload = {}) {
      if (payload.type) {
        Vue.set(state.loadings, payload.type, false);
        Vue.delete(state.errors, payload.type);

        checkPageReady(() => {
          state.pageNotBlocked = true;
        });
      }
    },
    DLH_ERROR(state, payload = {}) {
      if (payload.type) {
        Vue.set(state.errors, payload.type, true);
        Vue.delete(state.errors, payload.type);
      }
    },
  },
  // namespaced: true,
};

import vars from '@/config/vars';

export default {
  state: {
    currentTheme: 'main',
    networkOffline: false,
    languageId: vars.DEFAULT_LANG_ID,
    isMaintenanceMode: false,
    maintenanceData: {
      header: '',
      text: '',
      title: '',
    },
  },
  getters: {
    maintenanceData(state) {
      return state.maintenanceData;
    },
  },
  mutations: {
    SET_CURRENT_THEME(state, value) {
      state.currentTheme = value;
    },
    SET_NETWORK_OFFLINE(state, value) {
      state.networkOffline = value;
    },
    SET_MAINTENANCE_MODE(state, payload = {}) {
      const { value, data } = payload;

      state.isMaintenanceMode = value;

      if (data) {
        Object.assign(state.maintenanceData, data);
      }
    },
  },
  namespaced: true,
};

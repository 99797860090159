import _ from 'lodash';
import { LOGIN_TYPE } from "@/config/enums";

const initialState = () => ({
  email: '',
  rememberMe: false,
  loginType: LOGIN_TYPE.PASSWORD
});

export default {
  state: initialState(),
  getters: {
    isRememberMe(state) {
      return state.rememberMe;
    },
    loginType(state) {
      return state.loginType;
    },
    isCorporateLogin(state) {
      return state.loginType === LOGIN_TYPE.CORPORATE;
    },
    email(state) {
      return state.email;
    },
  },
  mutations: {
    UPDATE_LOGIN(state, payload = {}) {
      _.merge(state, payload);
    },
    CLEAR_LOGIN() {
      this.state = initialState();
    },
  },
  actions: {
    async saveDefaultLogin({ getters, commit, state }, payload = {}) {
      commit('UPDATE_LOGIN', {
        ...payload,
        rememberMe: false,
        loginType: LOGIN_TYPE.PASSWORD
      });
    },
    async saveCorporateLogin({ getters, commit, state }, payload = {}) {
      commit('UPDATE_LOGIN', {
        ...payload,
        loginType: LOGIN_TYPE.CORPORATE
      });
    },

  },
  namespaced: true,
};

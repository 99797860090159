import store from '@/store';

function hjSetAttributes() {
  const userId = store.getters['Account/userId'] || null;
  let attrs = {};

  if (userId) {
    attrs = {
      name: store.getters['Account/getSettingsValue']('user.name'),
      email: store.getters['Account/getSettingsValue']('user.email'),
    };

    Object.keys(attrs).forEach(attrKey => {
      if (!attrs[attrKey]) {
        delete attrs[attrKey];
      }
    });
  }

  if (typeof window.hj !== 'undefined') {
    window.hj('identify', userId, attrs);
  }
}

export {
  hjSetAttributes,
};

import Vue from 'vue';
import {
  deleteSearch,
  getSearchesList,
  postSearch,
  patchSearch,
  getSavedSearchNotifications,
} from "@/api/repositories/searchesRepository";

const initialState = () => ({
  userSearches: {}, // by listTypeId
  userSearchNotifications: {}, // by searchId
  userSelectedSearches: {}, // by listTypeId
  showSSNRemovedMessage: false,
});

export default {
  state: initialState(),
  getters: {
    getUserSearches: (state) => (listTypeId) => {
      return state.userSearches[listTypeId] || [];
    },
    getUserSearchNotifications: (state) => (searchId) => {
      return state.userSearchNotifications[searchId] || [];
    },
    getSelectedSearch: (state) => (listTypeId) => {
      return state.userSelectedSearches[listTypeId] || null;
    },
    isShowSSNRemovedMessage(state) {
      return state.showSSNRemovedMessage;
    },
  },
  mutations: {
    SET_USER_SEARCHES(state, payload) {
      Vue.set(state.userSearches, payload.listTypeId, payload.searches);
    },
    UPDATE_USER_SEARCH(state, payload) {
      const { listTypeId, searchId, data } = payload;
      const searches = state.userSearches[listTypeId] || [];
      const searchIndex = searches.findIndex(el => el.id === searchId);

      if (searchIndex !== -1) {
        Object.assign(searches[searchIndex], data);
      }
    },
    DELETE_USER_SEARCH(state, payload) {
      const { listTypeId, searchId } = payload;
      const searches = state.userSearches[listTypeId] || [];

      Vue.set(state.userSearches, listTypeId, searches.filter(el => el.id !== searchId));
    },
    ADD_USER_SEARCH(state, payload) {
      const searchData = payload.data;

      if (searchData.list_type_id) {
        const searches = state.userSearches[searchData.list_type_id] || [];
        Vue.set(state.userSearches, searchData.list_type_id, [searchData, ...searches]);
      }
    },
    SET_SEARCH_NOTIFICATIONS(state, payload) {
      Vue.set(state.userSearchNotifications, payload.searchId, payload.notifications);
    },
    DELETE_SEARCH_NOTIFICATIONS(state, payload) {
      if (!state.userSearchNotifications[payload.searchId]) {
        return;
      }

      Vue.delete(state.userSearchNotifications, payload.searchId);
    },
    SET_USER_SELECTED_SEARCH(state, payload) {
      const { listTypeId, data } = payload;

      Vue.set(state.userSelectedSearches, listTypeId, {
        searchId: null,
        ssn: null,
        ssnAllTotal: 0,
        ...data,
      });
    },
    UPDATE_USER_SELECTED_SEARCH(state, payload) {
      const { listTypeId, data } = payload;
      const selectedSearch = state.userSelectedSearches[listTypeId] || {};

      Vue.set(state.userSelectedSearches, listTypeId, {
        ...selectedSearch,
        ...data,
      });
    },
    DELETE_USER_SELECTED_SEARCH(state, payload) {
      const { listTypeId } = payload;

      if (!state.userSelectedSearches.hasOwnProperty(listTypeId)) {
        return;
      }

      Vue.delete(state.userSelectedSearches, listTypeId);
    },
    CLEAR_USER_SEARCHES(state) {
      state.userSearches = {};
      state.userSearchNotifications = {};
      state.userSelectedSearches = {};
    },
    SET_SHOW_SSN_REMOVED_MESSAGE(state, value) {
      state.showSSNRemovedMessage = value;
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
  },
  actions: {
    async fetchSearchNotifications({ commit, state, getters }, payload) {
      const { searchId } = payload;
      // const existNotifications = state.userSearchNotifications[searchId];

      // if (existNotifications) {
      //   return Promise.resolve(existNotifications);
      // }

      const query = {
        filter: {
          search_id: {
            in: [searchId],
          },
          user_id: {
            in: [getters['Account/userId']],
          },
          client_id: {
            in: [getters['Account/getSettingsValue']('client.id')],
          },
        },
        sort: {
          created_at: {
            order: 'd',
          },
        },
      };
      let res = [];

      try {
        const response = await getSavedSearchNotifications(query);

        res = response?.data || [];
        commit('SET_SEARCH_NOTIFICATIONS', {
          searchId,
          notifications: res,
        });
      } catch (error) {
        console.log(error);
      }

      return Promise.resolve(res);
    },
    async fetchUserSearches({ commit, state, getters }, payload) {
      const { listTypeId } = payload;
      const existSearches = state.userSearches[listTypeId];

      if (existSearches) {
        return Promise.resolve(existSearches);
      }

      const query = {
        filter: {
          list_type_id: {
            eq: listTypeId,
          },
          user_id: {
            in: [getters['Account/userId']],
          },
          client_id: {
            in: [getters['Account/getSettingsValue']('client.id')],
          },
        },
        sort: {
          modified_at: {
            order: 'd',
          },
        },
      };
      let res = [];

      try {
        const response = await getSearchesList(query);

        res = response?.data || [];
        commit('SET_USER_SEARCHES', {
          listTypeId,
          searches: res,
        });
      } catch (error) {}

      return Promise.resolve(res);
    },
    async deleteUserSearch({ commit, state, getters }, payload) {
      const { listTypeId, searchId } = payload;
      let res;

      try {
        res = await deleteSearch(searchId);

        if (res) {
          commit('DELETE_USER_SEARCH', { listTypeId, searchId });
          commit('DELETE_SEARCH_NOTIFICATIONS', { searchId });
        }
      } catch (error) {}

      return Promise.resolve(res);
    },
    async addUserSearch({ commit, state, getters }, payload) {
      const { data } = payload;
      let res;

      try {
        res = await postSearch(data);

        if (res) {
          commit('ADD_USER_SEARCH', { data: res });
        }
      } catch (error) {}

      return Promise.resolve(res);
    },
    async updateUserSearch({ commit, state, getters }, payload) {
      const { listTypeId, searchId, data } = payload;
      let res;

      try {
        res = await patchSearch(searchId, data);

        if (res) {
          commit('UPDATE_USER_SEARCH', {
            listTypeId,
            searchId,
            data,
          });
        }
      } catch (error) {}

      return Promise.resolve(res);
    },
  },
  // namespaced: true,
};

import guest from "@/router/middleware/guest";

const Login = () => import(/* webpackChunkName: "login" */ '@/views/pages/login/Login');
const OAuth = () => import(/* webpackChunkName: "oauth" */ '@/views/pages/oauth/OAuth');
const UserInvite = () => import(/* webpackChunkName: "user-invite" */ '@/views/pages/user-invite/UserInvite');
const InviteSso = () => import(/* webpackChunkName: "user-invite" */ '@/views/pages/InviteSso.vue');

export default [
  {
    meta: {
      breadcrumb: { name: 'invite' },
      component: 'invite',
      dictKey: 'Invite',
      auth: true,
    },
    path: '/invite',
    name: 'Invite',
    component: UserInvite,
  },
  {
    meta: {
      component: 'invite',
      dictKey: 'Invite',
      auth: true,
      systemPage: true,
    },
    path: '/invite-sso',
    name: 'InviteSso',
    component: InviteSso,
  },
  {
    meta: {
      breadcrumb: { name: 'login' },
      middleware: [
        guest,
      ],
      dictKey: 'Login',
      auth: true,
      forbiddenForUserSubdomain: true,
    },
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    meta: {
      dictKey: 'OAuth',
      auth: true,
      forbiddenForUserSubdomain: true,
    },
    path: '/oauth',
    name: 'OAuth',
    component: OAuth,
  },
];

export function checkRequiredQuery(next, to) {
  const hasRequiredQuery = to.meta.requiredQuery &&
    Object.keys(to.meta.requiredQuery).some(key => !to.query.hasOwnProperty(key));

  if (hasRequiredQuery) {
    next({
      ...to,
      query: { ...to.query, ...to.meta.requiredQuery },
    });
    return true;
  }

  return false;
}

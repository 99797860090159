import Vue from "vue";

const initialPageSection = () => ({
  visible: undefined,
});

const initialState = () => ({
  pageSections: {},
});

function setPageSectionValue(data, payload) {
  const { sectionKey, key, value } = payload;

  if (!data[sectionKey]) {
    Vue.set(data, sectionKey, initialPageSection());
  }

  Object.assign(data[sectionKey], {
    [key]: value,
  });
}

function getPageSectionValue(data, payload) {
  const { key, sectionKey } = payload;
  const sectionData = data[sectionKey] || initialPageSection();

  return sectionData[key];
}

export default {
  state: initialState(),
  getters: {
    getPageSectionValue: state => payload => {
      return getPageSectionValue(state.pageSections, payload);
    },
  },
  mutations: {
    SET_PAGE_SECTION_VALUE(state, payload) {
      setPageSectionValue(state.pageSections, {
        sectionKey: payload.sectionKey,
        key: payload.key,
        value: payload.value,
      });
    },
  },
};

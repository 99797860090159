// Containers
export const DashboardContainer = () => import(/* webpackChunkName: "pages" */ '@/containers/DashboardContainer');
export const PrintContainer = () => import(/* webpackChunkName: "print-container" */ '@/containers/PrintContainer');

// Print pages for dev
export const PrintProjects = () => import(/* webpackChunkName: "print-projects" */ '@/views/print/PrintProjects');
export const PrintCompanies = () => import(/* webpackChunkName: "print-companies" */ '@/views/print/PrintCompanies');
export const PrintContacts = () => import(/* webpackChunkName: "print-contacts" */ '@/views/print/PrintContacts');
export const PrintTenders = () => import(/* webpackChunkName: "print-tenders" */ '@/views/print/PrintTenders');
export const PrintProject = () => import(/* webpackChunkName: "print-project" */ '@/views/print/print-pages/PagePrintProject');
export const PrintContact = () => import(/* webpackChunkName: "print-contact" */ '@/views/print/print-pages/PagePrintContact');
export const PrintTender = () => import(/* webpackChunkName: "print-tender" */ '@/views/print/print-pages/PagePrintTender');
export const PrintCompany = () => import(/* webpackChunkName: "print-company" */ '@/views/print/print-pages/PagePrintCompany');

// Leads
export const Project = () => import(/* webpackChunkName: "project" */ '@/views/leads/Project');
export const Company = () => import(/* webpackChunkName: "company" */ '@/views/leads/Company');
export const Contact = () => import(/* webpackChunkName: "contact" */ '@/views/leads/Contact');
export const Tender = () => import(/* webpackChunkName: "tender" */ '@/views/leads/Tender');

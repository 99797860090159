import Vue from "vue";
import { loadCustomFieldsets } from "@/shared/api-helpers/customFieldsApiHelper";

const initialEntityData = () => ({
  customFieldsets: undefined,
  modules: undefined, // addons
  conditionalStatuses: [],
});

const initialState = () => ({
  dataByEntities: {},
});

function setEntityValue(data = {}, payload) {
  const { entityTypeId, value, key } = payload;

  if (!data[entityTypeId]) {
    const entityValues = initialEntityData();
    Vue.set(data, entityTypeId, entityValues);
  }

  const entityValue = data[entityTypeId]

  Vue.set(entityValue, key, value);
}

function getEntityValue(data, payload) {
  const { key, entityTypeId } = payload;
  const entityListData = data[entityTypeId] || initialEntityData();

  return entityListData[key];
}

export default {
  state: initialState(),
  getters: {
    getCustomFieldsets: state => payload => {
      return getEntityValue(state.dataByEntities, {
        entityTypeId: payload.entityTypeId,
        key: 'customFieldsets',
      }) || [];
    },
    getEntityConditionalStatuses: state => payload => {
      return getEntityValue(state.dataByEntities, {
        entityTypeId: payload.entityTypeId,
        key: 'conditionalStatuses',
      });
    },
  },
  mutations: {
    SET_CUSTOM_FIELDSET(state, payload) {
      setEntityValue(state.dataByEntities, {
        entityTypeId: payload.entityTypeId,
        key: 'customFieldsets',
        value: payload.data,
      });
    },
    SET_ENTITY_MODULES(state, payload) {
      setEntityValue(state.dataByEntities, {
        entityTypeId: payload.entityTypeId,
        key: 'modules',
        value: payload.modules,
      });
    },
    SET_CONDITIONAL_STATUSES(state, payload) {
      setEntityValue(state.dataByEntities, {
        entityTypeId: payload.entityTypeId,
        key: 'conditionalStatuses',
        value: payload.statuses,
      });
    },
    RESET_ENTITIES(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
  },
  actions: {
    async fetchCustomFieldsets({ commit, state, getters }, payload) {
      const { entityTypeId } = payload;
      const existFieldsets = getEntityValue(state, {
        entityTypeId: entityTypeId,
        key: 'customFieldsets',
      });

      if (existFieldsets) {
        return Promise.resolve(existFieldsets);
      }

      let fieldsets = [];

      try {
        fieldsets = await loadCustomFieldsets({
          entity_type_id: entityTypeId,
          client_id: getters['Account/getSettingsValue']('client.id'),
        });

        commit('SET_CUSTOM_FIELDSET', {
          entityTypeId,
          data: fieldsets,
        });
      } catch (error) {}

      return Promise.resolve(fieldsets);
    },
  },
};

function isForbiddenForUserSubdomain(to) {
  return !!(window.logged_as_user && to?.meta.forbiddenForUserSubdomain);
}

export function checkAccessToUserSubdomain(next, to) {
  if (isForbiddenForUserSubdomain(to)) {
    window.location.href = window.location.origin.replace(/user./g, '');
    return true;
  }

  return false;
}

import GeoRepository from '@/api/repositories/geoRepository';
import { prepareAdministrativeUnits } from "@/shared/administrative-units";

export async function loadLocationList(_config = {}) {
  const config = _.defaults({}, _config, {
    showCountries: false,
    all: false,
    accessType: null,
    countries: null,
  });
  const query = {
    sort: {
      title: {
        order: 'a',
      },
    },
    limit: process.env.VUE_APP_MAX_LIMIT,
    offset: 0,
    fields: [
      'id',
      'title',
      'administrative_unit_type_id',
      'administrative_units',
      'path',
      'country_id',
      // 'mark',
    ],
    view_type: 'tree',
  };
  let res = [];

  if (config.countries) {
    query.filter = Object.assign({}, query.filter, {
      country_id: { in: config.countries },
    });
  }

  if (config.accessType) {
    query.access_type = config.accessType;
  }

  try {
    const response = await GeoRepository.getAdministrativeUnits(query);
    const adUnits = prepareAdministrativeUnits(response?.data || []);

    if (!adUnits.length) {
      return Promise.resolve(adUnits);
    }

    if (config.showCountries || adUnits.length > 1) {
      res = adUnits.map(country => {
        return {
          ...country,
          items: country.items || [],
          isGroup: true,
        };
      });
    } else {
      res = adUnits[0]?.items || [];
    }
  } catch (error) {
    console.log(error);
  }

  return res;
}

export default {
  loadLocationList,
};

import {
  getCustomFieldset,
  getCustomFieldsets,
  getCustomFields,
  getCustomFieldSelections,
} from "@/api/repositories/customFieldsRepository";
import { convertStringToKey } from "@/shared/utils";

const MAX_LIMIT = 100;

async function loadFieldsets(filters = {}) {
  let res = [];

  try {
    const response = await getCustomFieldsets({
      filter: {
        entity_type_id: {
          in: [filters.entity_type_id],
        },
        client_id: {
          in: [filters.client_id],
        },
      },
      sort: {
        id: {
          order: 'd',
        },
      },
      limit: 1,
    });
    res = response?.data || [];
  } catch (error) {}

  return res;
}

async function loadFieldsetById(id) {
  let res = [];

  try {
    const response = await getCustomFieldset(id);
    res = response?.data || [];
  } catch (error) {}

  return Promise.resolve(res);
}

async function loadFields(fieldsetIds = [], filters = {}) {
  const filter = {
    custom_fieldset_id: {
      in: fieldsetIds,
    },
  };
  let res = [];

  if (filters.show_in_filter) {
    Object.assign(filter, {
      show_in_filter: {
        eq: 1,
      },
    });
  }

  try {
    const response = await getCustomFields({
      limit: MAX_LIMIT,
      filter,
      sort: {
        priority: {
          order: 'a',
        },
      },
    });
    res = response?.data || [];
  } catch (error) {}

  return res;
}

async function loadSelections(fieldsetIds = [], offset = 0) {
  let res;

  try {
    res = await getCustomFieldSelections({
      limit: MAX_LIMIT,
      offset,
      filter: {
        custom_fieldset_id: {
          in: fieldsetIds,
        },
      },
      sort: {
        id: {
          order: 'a',
        },
      },
    });
  } catch (error) {}

  return res;
}

export async function loadCustomFieldsetsFields(_fieldsets = [], filters = {}) {
  const fieldsets = _.cloneDeep(_fieldsets);
  const fieldsetIds = fieldsets.map(el => el.id);
  const requests = [
    loadFields(fieldsetIds, filters),
    loadSelections(fieldsetIds),
  ];
  const responses = await Promise.all(requests);
  const allFields = responses[0];
  let allSelections = responses[1]?.data || [];

  // load other selections
  const allSelectionsTotal = responses[1]?.meta?.total || 0;
  const amountOfSelectionRequests = allSelectionsTotal > MAX_LIMIT ? allSelectionsTotal / MAX_LIMIT - 1 : 0;

  if (amountOfSelectionRequests > 0) {
    const selectionRequests = [];

    for (let i = 0; i < amountOfSelectionRequests; i++) {
      selectionRequests.push(loadSelections(fieldsetIds, (i + 1) * MAX_LIMIT));
    }

    const selectionResponses = await Promise.all(selectionRequests);

    selectionResponses.forEach(response => {
      const selections = response?.data || [];
      allSelections = allSelections.concat(selections);
    });
  }
  //

  fieldsets.forEach((fieldset, fieldsetIndex)  => {
    const fields = allFields
      .filter(el => el.custom_fieldset_id === fieldset.id)
      .map(el => ({ ...el, dataCy: `cf_${convertStringToKey(el.name)}` }));
    const fieldsIds = fields.map(el => el.id);
    const selections = allSelections
      .filter(el => fieldsIds.includes(el.custom_field_id))
      .map(el => ({ ...el, dataCy: `cfs_${convertStringToKey(el.value)}` }));

    fields.forEach((field, fieldIndex) => {
      Object.assign(fields[fieldIndex],{
        selections: selections
          .filter(el => el.custom_field_id === field.id)
          .sort((a, b) => a.priority - b.priority),
      });
    });

    fieldsets[fieldsetIndex].fields = fields;
  });

  return fieldsets;
}

export async function loadCustomFieldsets(filters = {}) {
  const fieldsets = await loadFieldsets(filters);

  if (!fieldsets.length) {
    return fieldsets;
  }

  return await loadCustomFieldsetsFields(fieldsets, filters);
}

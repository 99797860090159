import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

// Email logs
const SupportEmailLogs = () => import(/* webpackChunkName: "support-email-logs" */ '@/views/support/email-logs/EmailLogs');

// SSN
const SupportSSNList = () => import(/* webpackChunkName: "support-ssn-list" */ '@/views/support/ssn/SavedSearchNotifications');

// User transfer tasks
const SupportUserTransferTasks = () => import(/* webpackChunkName: "support-user-transfer-tasks" */ '@/views/support/user-transfer-tasks/UserTransferTasks');
const SupportEditorUserTransferTask = () => import(/* webpackChunkName: "support-user-transfer-task-editor" */ '@/views/support/user-transfer-tasks/UserTransferTaskEditor')

export default [
  {
    meta: {
      breadcrumb: { name: 'logs' },
      dictKey: 'DashboardContainer',
      menuId: 71,
    },
    path: '/logs',
    redirect: '/logs/email-logs',
    name: 'Logs',
    component: DashboardContainer,
    children: [
      {
        path: 'email-logs',
        redirect: '/logs/email-logs',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'email_sender_logs',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Email logs',
              },
              dictKey: 'SupportEmailLogs',
              menuId: 68,
            },
            path: '/logs/email-logs',
            name: 'SupportEmailLogs',
            component: SupportEmailLogs,
          },
        ],
      },
      {
        path: 'ssn',
        redirect: '/logs/ssn-list',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'ssn_list',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Saved search notifications',
              },
              dictKey: 'SupportSSNList',
              menuId: 69,
            },
            path: '/logs/ssn-list',
            name: 'SupportSSNList',
            component: SupportSSNList,
          },
        ],
      },
      {
        path: 'user-transfer-tasks',
        redirect: '/logs/user-transfer-tasks',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'user_transfer_tasks',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'User transfer tasks',
              },
              dictKey: 'SupportUserTransferTasks',
              menuId: 70,
            },
            path: '/logs/user-transfer-tasks',
            name: 'SupportUserTransferTasks',
            component: SupportUserTransferTasks,
          },
          {
            meta: {
              breadcrumb: { name: 'Create user transfer task' },
              component: 'user_transfer_tasks',
              middleware: [validateComponent],
              dictKey: 'SupportUserTransferTasks',
              parentMenuId: 70,
            },
            path: 'new',
            name: 'SupportEditorUserTransferTaskNew',
            component: SupportEditorUserTransferTask,
          },
        ],
      },
    ],
  },
];

/*
  Entity class for extends
 */

import Vue from 'vue';
import store from "@/store";
import router from "@/router";
import { IndexedArray } from "@/shared/proxies";
import { LIST_TYPES, SOURCE_TYPES } from "@/config/enums";
import { formatDateTime } from "@/shared/utils";
import { Client } from "@/entities";

//
export default class BaseEntity {
  static fieldsBlackList = [];
  static idKey = 'id';
  static routes = {};
  static entityTypeId = null;
  static navigationIdKey = '';

  static getEntityTypeText() {
    return Vue.prototype.$vDict('global.entity_type_text.text');
  }

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('global.list_items_plural_text.text');
  }

  static getEntityTypeTextByLang(type = 'single', langs = []) {
    const langId = Vue.prototype.$getDictLanguage('id');
    let resType = langs.includes(langId) ? type : (type === 'plural' ? 'single' : 'plural');

    return resType === 'single' ? this.getEntityTypeText() : this.getEntityPluralTypeText();
  }

  constructor(defaultData = () => ({}), data = {}, listInstance = null) {
    const {
      navigationIdKey,
    } = BaseEntity;

    this.id = data.id;
    this.navigationId = navigationIdKey ? _.get(data, navigationIdKey) : this.id;
    this.type = 'entity';
    this.listInstance = listInstance;
    this.formData = {};
    this.data = {};
    this._formattedData = {};

    this.selfClass = BaseEntity;
    this.prepareItemData(data, defaultData);
  }

  static getAllActions() {
    return [];
  }

  static getAddLink() {
    if (!this.routes.create) {
      return;
    }

    return {
      name: this.routes?.create,
      query: { from: router.currentRoute.fullPath },
    };
  }

  static goBack() {
    const { query } = router.currentRoute;
    const { from } = query;
    const routeBack = from || this.routes.list;
    const route = {};

    if (from) {
      route.path = routeBack;
      delete query.from;
    } else {
      route.name = routeBack;
    }

    route.query = query;

    router.push(route);
  }

  prepareItemData(_data, _defaultData) {
    const defaultData = _defaultData();
    const data = _.cloneDeep(_data);

    _.merge(this.data, defaultData, data);
  }

  getTypeName() {
    return '';
  }

  getData() {
    return _.cloneDeep(this.data);
  }

  getProperty(propKey) {
    return _.get(this.selfClass, propKey);
  }

  getName() {
    return this.getValue('name');
  }

  hasValue(key) {
    return this.data.hasOwnProperty(key);
  }

  getValue(key, def = null) {
    return _.get(this.data, key) ?? def;
  }

  getValues(keys = []) {
    const values = keys.map(key => this.data[key]);
    return _.compact(values);
  }

  getItemFromEnum(enumKey, id) {
    const enumItems = Vue.prototype.$getEnumsList(enumKey);
    return enumItems.find(el => el.id === id);
  }

  getDatasetItem(dataset, key, itemId) {
    if (!dataset[key]) {
      return null;
    }

    const data = dataset[key] || new IndexedArray([]);
    return data.findById(itemId);
  }

  getUser(dataset, key = 'user_id') {
    const userId = this.getValue(key);
    let user;

    if (dataset?.users) {
      user = dataset?.users.findById(userId);
    } else {
      user = store.getters['UserClient/getUser'](userId);
    }

    return user ? {
      ...user,
      name: user.me_name || user.name || user.email,
    } : null;
  }

  getClient(dataset = {}) {
    const clientData = this.getDatasetItem(dataset, Client.datasetKey, this.getValue('client_id'));
    return clientData ? new Client(clientData) : null;
  }

  setValue(key, value) {
    Vue.set(this.data, key, value);
  }

  async patchData(data, ignoreList = false) {
    Object.keys(data).forEach(key => {
      this.setValue(key, data[key]);
    });

    if (this.listInstance?.onChangeEntity && !ignoreList) {
      await this.listInstance.onChangeEntity(this, data);
    }
  }

  getCurrentRoutes() {
    const users = this.getValue('users', []);
    return users.length > 0 && !!this.selfClass.userSectionRoutes ?
      this.selfClass.userSectionRoutes :
      this.selfClass.routes;
  }

  getLinkTo() {
    const currentRoutes = this.getCurrentRoutes();

    if (!currentRoutes?.view) {
      return;
    }

    const res = {
      name: currentRoutes.view,
      params: {
        id: this.id,
      },
      query: {},
    };

    if (this.listInstance) {
      const { statusId, config, listTypeId } = this.listInstance;

      if (listTypeId) {
        res.query.listTypeId = listTypeId;
      }

      if (config.isMain) {
        res.params.fromMainList = true;
      }

      if (statusId) {
        res.query.statusId = statusId;
      }
    }

    return res;
  }

  getFormattedValue(key, dataset, fields = []) {
    let res = this.getAsyncFieldValue(key, dataset, fields);

    if (res === undefined) {
      res = _.has(this._formattedData, key) ? _.get(this._formattedData, key) : this.getFieldValue(key);
    }

    return res;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'created_at':
      case 'modified_at':
        res = fieldValue ? formatDateTime(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE_TIME, userZone: true }) : '';
        break;
      default:
        res = fieldValue;
    }

    return res;
  }

  getAsyncFieldValue() {}

  prepareFieldsData(fieldset, listTypeId = null) {
    const res = this._formattedData || {};

    fieldset.forEach(fieldKey => {
      const fieldValue = this.getFieldValue(fieldKey, listTypeId);

      if (fieldValue !== undefined) {
        res[fieldKey] = fieldValue;
      }
    });

    this._formattedData = res;
  }

  goToPrint() {
    router.push({
      path: `${router.currentRoute.path}/print`,
      query: {
        destination: router.currentRoute.fullPath,
      },
    }).catch((e) => {
      console.log(e);
    });
  }

  getListType() {
    const listTypes = _.invert(LIST_TYPES);
    const listTypeId = this.getValue('list_type_id');
    const key = listTypes[listTypeId];

    return {
      id: listTypeId,
      name: Vue.prototype.$vDict(`enums.list_type_${key.toLowerCase()}.text`),
    };
  }

  getPostcodeTextFromValue(value) {
    return value ? value.replace(' ', '\u00A0') : '';
  }

  goToView() {
    const linkTo = this.getLinkTo();

    if (this.listInstance) {
      this.listInstance.onGoToEntity(this.id);
    }

    router.push(linkTo);
  }

  getEditLink() {
    const { routes } = this.selfClass;

    if (!routes.edit) {
      return;
    }

    return {
      name: routes.edit,
      params: {
        id: this.id,
      },
      query: {
        from: router.currentRoute.fullPath,
      },
    };
  }

  goToEdit() {
    const editLink = this.getEditLink();

    if (!editLink) {
      return;
    }

    router.push(editLink);
  }

  linkToHandler(evt) {
    if (this.listInstance) {
      this.listInstance.onGoToEntity(this.id);
    }

    if (!evt.shiftKey || !this.selfClass.routes.edit) {
      return;
    }

    evt.preventDefault();
    this.goToEdit();
  }

  getFormData() {
    if (!_.isEmpty(this.formData)) {
      return this.formData;
    }

    return this.getDataWithoutId();
  }

  getDataWithoutId() {
    const formData = this.getData();
    const idKey = this.selfClass.idKey;

    delete formData[idKey];

    return formData;
  }

  getActions() {
    return this.selfClass.getAllActions();
  }

  async doAction(action) {
    switch (action.key) {
      case 'view':
        this.goToView();
        break;
      case 'edit':
        this.goToEdit();
        break;
      case 'delete':
        await this.delete();
        break;
      default:
    }
  }

  async delete() {
    const { deleteItemFunc } = this.selfClass;

    if (!deleteItemFunc) {
      return;
    }

    if (confirm(Vue.prototype.$vDict('global.text_are_you_sure.text'))) {
      const res = await deleteItemFunc(this.id);

      if (res && this.listInstance) {
        await this.listInstance.deleteItem(this.id);
      }

      if (res && router.currentRoute.name === this.selfClass.routes.view) {
        this.selfClass.goBack();
      }

      if (router.currentRoute.name === this.selfClass.routes.view) {
        this.selfClass.goBack();
      }
    }
  }

  setFormData(formData = {}) {
    this.formData = formData;
  }

  getPostData(formData) {
    return formData;
  }

  getPutData(formData) {
    return formData;
  }

  initCustomName() {
    const sourceType = this.getSourceType();
    let id;

    if (!sourceType) {
      return '';
    }

    switch (sourceType.id) {
      case SOURCE_TYPES.SMART:
      case SOURCE_TYPES.SHARP:
        id = this.id;
        break;
      default:
        id = this.getValue('source_id') || this.id;
    }

    this.name = `${sourceType.name} ${id}` || 'Unknown';
  }

  getSourceType() {
    const sourceTypeId = this.getValue('source_type_id') || SOURCE_TYPES.SMART;
    return this.getItemFromEnum('SOURCE_TYPES', sourceTypeId);
  }

  clone() {
    const data = _.cloneDeep(this.data);
    return new this.selfClass(data)
  }

  async reqPatchData(data = {}) {
    const { patchItemFunc } = this.selfClass;

    if (!patchItemFunc) {
      return;
    }

    const response = await patchItemFunc(this.id, data);
    await this.patchData(data);

    return response;
  }

  async changeSuspendedValue(is_suspended) {
    let response;

    try {
      response = await this.reqPatchData({
        is_suspended,
      });
    } catch (e) {}

    if (response) {
      Vue.prototype.$notifToastr('success', Vue.prototype.$vDict('global.text_item_updated_success.text'));
    } else {
      Vue.prototype.$notifUnexpectedError();
    }

    return response;
  }

  hasExternalSourceType() {
    const sourceTypeId = this.getValue('source_type_id');
    return sourceTypeId && ![
      SOURCE_TYPES.SMART,
      SOURCE_TYPES.SHARP,
    ].includes(sourceTypeId);
  }

  selfFetch() {
    throw 'Not implemented';
  }

  async reload() {
    try {
      const entityData = await this.selfFetch();

      if (entityData && Object.keys(entityData).length) {
        await this.patchData(entityData);
      }
    } catch (error) { }
  }
}

import Vue from 'vue';
import _ from 'lodash';

export default {
  state: {
    lookups: {},
    lookupEnums: {},
    loadingDictRoute: '', // 'main' || 'secondary' || 'silent'
  },
  getters: {
    getLookups: state => (key) => {
      return _.get(state.lookups, key);
    },
    getLookupEnum: state => (key) => {
      return _.get(state.lookupEnums, key);
    },
    isLoadingDictRoute: state => (key) => {
      return state.loadingDictRoute === key;
    },
  },
  mutations: {
    SET_LOADING_DICT_ROUTE(state, value) {
      state.loadingDictRoute = value;
    },
    SET_LOOKUPS(state, payload) {
      const { sectionKey, lookupEnums, lookupData } = payload;

      state.lookupEnums = Object.assign(state.lookupEnums, Object.freeze(lookupEnums || {}));
      Vue.set(state.lookups, sectionKey, Object.freeze(lookupData));
    },
  },
  actions: {},
  // namespaced: true,
};

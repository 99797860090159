export const getIndexedArray = (idKey = 'id') => {
  return new Proxy(Array, {
    construct(target, [args]) {
      const index = {}
      args.forEach(item => (index[item[idKey]] = item))

      return new Proxy(new target(...args), {
        get(arr, prop) {
          switch (prop) {
            case 'push':
              return item => {
                if (!index[item[idKey]]) {
                  index[item[idKey]] = item
                  arr[prop].call(arr, item)
                }
              }
            case 'findById':
              return id => index[id];
            case 'hasById':
              return id => index.hasOwnProperty(id);
            case 'updateById':
              return (id, item) => {
                Object.assign(index[id], item);
              }
            default:
              return arr[prop]
          }
        }
      })
    }
  });
};

export const IndexedArray = getIndexedArray();

<script>
export default {
  methods: {
    hasSlot(slotName = 'default') {
      return !!this.$slots[slotName] || !!this.$scopedSlots[slotName];
    },
    notEmptySlot(slotName = 'default') {
      let res = (this.$slots[slotName] || this.$scopedSlots[slotName] || [])[0];
      if (!!res && res.text && /^\s+$/.test(res.text)) {
        res = false;
      }
      return !!res;
    },
  },
};
</script>

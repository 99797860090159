import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer, PrintContainer } from "@/router/components";
import {
  PrintProjects,
  PrintCompanies,
  PrintContacts,
  PrintTenders,
  PrintProject,
  PrintContact,
  PrintTender,
  PrintCompany,
  Project,
  Company,
  Contact,
  Tender,
} from "@/router/components";

const MyProjects = () => import(/* webpackChunkName: "my-projects" */ '@/views/sales/MyProjects');
const MyCompanies = () => import(/* webpackChunkName: "my-companies" */ '@/views/sales/MyCompanies');
const MyContacts = () => import(/* webpackChunkName: "my-contacts" */ '@/views/sales/MyContacts');
const MyTenders = () => import(/* webpackChunkName: "my-tenders" */ '@/views/sales/MyTenders');
const MyActivities = () => import(/* webpackChunkName: "my-activities" */ '@/views/sales/MyActivities');
const MyNotes = () => import(/* webpackChunkName: "my-notes" */ '@/views/sales/MyNotes');
const MyFiles = () => import(/* webpackChunkName: "my-files" */ '@/views/sales/MyFiles');

export default [
  {
    path: '/sales/print',
    redirect: '/sales/projects/print',
    name: 'SalesPrint',
    meta: {
      breadcrumb: {name: 'sales'},
      dictKey: 'PrintContainer',
    },
    component: PrintContainer,
    children: [
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'MyProjects',
        },
        path: '/sales/my-projects/print',
        name: 'PrintUserProjects',
        component: PrintProjects,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Project',
        },
        path: '/sales/my-projects/:id/print',
        name: 'PrintUserProject',
        component: PrintProject,
      },
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'MyCompanies',
        },
        path: '/sales/my-companies/print',
        name: 'PrintUserCompanies',
        component: PrintCompanies,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Company',
        },
        path: '/sales/my-companies/:id/print',
        name: 'PrintUserCompany',
        component: PrintCompany,
      },
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'MyContacts',
        },
        path: '/sales/my-contacts/print',
        name: 'PrintUserContacts',
        component: PrintContacts,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Contact',
        },
        path: '/sales/my-contacts/:id/print',
        name: 'PrintUserContact',
        component: PrintContact,
      },
      {
        meta: {
          breadcrumb: { name: 'print' },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'MyTenders',
        },
        path: '/sales/my-tenders/print',
        name: 'PrintUserTenders',
        component: PrintTenders,
      },
      {
        meta: {
          breadcrumb: { name: 'print', dynamic: true },
          component: 'print',
          middleware: [validateComponent],
          dictKey: 'Tender',
        },
        path: '/sales/my-tenders/:id/print',
        name: 'PrintUserTender',
        component: PrintTender,
      },
    ],
  },
  {
    path: '/sales',
    redirect: '/sales/my-projects',
    name: 'Sales',
    meta: {
      breadcrumb: { name: 'sales' },
      dictKey: 'DashboardContainer',
      menuId: 1,
    },
    component: DashboardContainer,
    children: [
      {
        path: '/sales/my-projects',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/sales/my-projects',
            name: 'MyProjects',
            component: MyProjects,
            meta: {
              requiredQuery: {
                status: 1,
              },
              component: 'user_projects',
              middleware: [validateComponent],
              breadcrumb: { name: 'my_projects' },
              dictKey: 'MyProjects',
              menuId: 2,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'project', dynamic: true },
              component: 'user_projects',
              middleware: [validateComponent],
              dictKey: 'Project',
              parentMenuId: 2,
            },
            path: ':id',
            name: 'MyProject',
            component: Project,
          },
        ],
      },
      {
        path: 'my-companies',
        redirect: '/sales/my-companies',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/sales/my-companies',
            name: 'MyCompanies',
            component: MyCompanies,
            meta: {
              requiredQuery: {
                folder: -1,
              },
              component: 'user_companies',
              middleware: [validateComponent],
              breadcrumb: { name: 'my_companies' },
              dictKey: 'MyCompanies',
              menuId: 4,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'company', dynamic: true },
              component: 'user_companies',
              middleware: [validateComponent],
              dictKey: 'Company',
              parentMenuId: 4,
            },
            path: ':id',
            name: 'MyCompany',
            component: Company,
          },
        ],
      },
      {
        path: 'my-contacts',
        redirect: '/sales/my-contacts',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/sales/my-contacts',
            name: 'MyContacts',
            component: MyContacts,
            meta: {
              component: 'user_contacts',
              middleware: [validateComponent],
              breadcrumb: { name: 'my_contacts' },
              dictKey: 'MyContacts',
              menuId: 5,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'person', dynamic: true },
              component: 'user_contacts',
              middleware: [validateComponent],
              dictKey: 'Contact',
              parentMenuId: 5,
            },
            path: ':id',
            name: 'MyContact',
            component: Contact,
          },
        ],
      },
      {
        path: 'my-tenders',
        redirect: '/sales/my-tenders',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/sales/my-tenders',
            name: 'MyTenders',
            component: MyTenders,
            meta: {
              requiredQuery: {
                status: 1,
              },
              component: 'user_tenders',
              middleware: [validateComponent],
              breadcrumb: { name: 'my_tenders' },
              dictKey: 'MyTenders',
              menuId: 3,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'tender', dynamic: true },
              component: 'user_tenders',
              middleware: [validateComponent],
              dictKey: 'Tender',
              parentMenuId: 3,
            },
            path: ':id',
            name: 'MyTender',
            component: Tender,
          },
        ],
      },
      {
        meta: {
          breadcrumb: { name: 'my_activities' },
          component: 'user_activities',
          middleware: [validateComponent],
          dictKey: 'MyActivities',
          menuId: 6,
        },
        path: 'my-activities',
        name: 'MyActivities',
        component: MyActivities,
      },
      {
        meta: {
          breadcrumb: { name: 'my_notes' },
          component: 'user_notes',
          middleware: [validateComponent],
          dictKey: 'MyNotes',
          menuId: 7,
        },
        path: 'my-notes',
        name: 'MyNotes',
        component: MyNotes,
      },
      {
        meta: {
          component: 'user_files',
          middleware: [validateComponent],
          dictKey: 'MyFiles',
          menuId: 59,
        },
        path: 'my-files',
        name: 'MyFiles',
        component: MyFiles,
      },
    ],
  },
];

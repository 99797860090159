<script>
export default {
  name: 'mixin-modal',
  props: {
    showModal: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },
  data() {
    return {
      showParams: {},
    };
  },
  watch: {
    showModal: {
      handler() {
        this.doShowModal();
      },
    },
  },
  mounted() {
    this.doShowModal();
  },
  methods: {
    show(showParams = {}) {
      Object.assign(this.showParams, _.cloneDeep(showParams));
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onHiddenModal() {
      this.$emit('update:showModal', false);
    },
    doShowModal() {
      if (this.$refs.modal && this.showModal) {
        this.$refs.modal.show();
      }
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    onShowModal() {
      this.$emit('show');
    },
    onHideModal(...args) {
      this.$emit('hide', ...args);
    },
  },
};
</script>

import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

const UsageStatsClients = () => import(/* webpackChunkName: "usage-stats-clients" */ '@/views/usage-statistics/UsageStatsClients');
const UsageStatsUsers = () => import(/* webpackChunkName: "usage-stats-users" */ '@/views/usage-statistics/UsageStatsUsers');
const UsageStatsClient = () => import(/* webpackChunkName: "usage-stats-client" */ '@/views/usage-statistics/UsageStatsClient');
const UsageStatsUser = () => import(/* webpackChunkName: "usage-stats-user" */ '@/views/usage-statistics/UsageStatsUser');

export default [
  {
    meta: {
      component: 'usage_stats_clients',
      breadcrumb: { name: 'usage_statistics' },
      dictKey: 'DashboardContainer',
      menuId: 51,
    },
    path: '/usage-statistics',
    redirect: '/usage-statistics/clients',
    name: 'UsageStatistics',
    component: DashboardContainer,
    children: [
      {
        path: 'clients',
        redirect: '/usage-statistics/clients',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/usage-statistics/clients',
            name: 'UsageStatisticsClients',
            component: UsageStatsClients,
            meta: {
              component: 'usage_stats_clients',
              breadcrumb: { name: 'usage_statistics_clients' },
              middleware: [
                validateComponent,
              ],
              dictKey: 'UsageStatisticsClients',
              menuId: 52,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'usage_statistics_client', dynamic: true },
              component: 'usage_stats_client',
              middleware: [
                validateComponent,
              ],
              dictKey: 'UsageStatisticsClient',
              parentMenuId: 52,
            },
            path: ':id',
            name: 'UsageStatisticsClient',
            component: UsageStatsClient,
          },
        ],
      },
      {
        path: 'users',
        redirect: '/usage-statistics/users',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '/usage-statistics/users',
            name: 'UsageStatisticsUsers',
            component: UsageStatsUsers,
            meta: {
              component: 'usage_stats_users',
              breadcrumb: { name: 'usage_statistics_users' },
              middleware: [
                validateComponent,
              ],
              dictKey: 'UsageStatisticsUsers',
              menuId: 53,
            },
          },
          {
            meta: {
              breadcrumb: { name: 'usage_statistics_user', dynamic: true },
              component: 'usage_stats_user',
              middleware: [
                validateComponent,
              ],
              dictKey: 'UsageStatisticsUser',
              parentMenuId: 53,
            },
            path: ':id',
            name: 'UsageStatisticsUser',
            component: UsageStatsUser,
          },
        ],
      },
    ],
  },
];

// const cookiesDomain = window.location.hostname;
const locationProtocol = window.location.protocol;

export const CookiesStorage = {
  set(keyName, value, expireTimes = 0, secure = false, sameSite = 'None') {
    const isSecure = secure || locationProtocol === 'https';
    window.$cookies.set(keyName, value, expireTimes, '/', null, isSecure, sameSite);
  },

  get(keyName) {
    if (this.hasKey(keyName)) {
      return window.$cookies.get(keyName);
    }
    return null;
  },

  remove(keyName) {
    window.$cookies.remove(keyName, '/');
  },

  hasKey(keyName) {
    return window.$cookies.isKey(keyName);
  },

  getAll() {
    return window.$cookies.keys();
  },

  removeAll() {
    window.$cookies.keys().forEach(cookie => this.remove(cookie));
  },
};

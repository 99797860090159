<template>
  <div class="dead-message-subscription-form">
    <b-form-group
      :state="!$v.formData.email.$anyError"
      class="fl form-group--required"
      :label="$vDict('dead_message_subscriptions.field_email.text')"
    >
      <base-input
        :class="{ 'is-invalid': $v.formData.email.$anyError }"
        name="email"
        size="lg"
        v-model="formData.email"
      />

      <b-form-invalid-feedback
        v-if="$v.formData.email.$anyError"
        v-html="getFormErrorsAsText('formData.email')"
      />
    </b-form-group>

    <b-form-group
      :state="!$v.formData.topic.$anyError"
      class="fl"
      :class="{ 'form-group--required': !formData.service_name }"
      :label="$vDict('dead_message_subscriptions.field_topic.text')"
    >
      <base-input
        :class="{ 'is-invalid': $v.formData.topic.$anyError }"
        name="topic"
        size="lg"
        v-model="formData.topic"
        show-clear-button
      />

      <b-form-invalid-feedback
        v-if="$v.formData.topic.$anyError"
        v-html="getFormErrorsAsText('formData.topic')"
      />
    </b-form-group>

    <b-form-group
      :state="!$v.formData.service_name.$anyError"
      class="fl"
      :label="$vDict('dead_message_subscriptions.field_service_name.text')"
      :class="{ 'form-group--required': !formData.topic }"
    >
      <base-input
        :class="{ 'is-invalid': $v.formData.service_name.$anyError }"
        name="service_name"
        size="lg"
        v-model="formData.service_name"
        show-clear-button
      />

      <b-form-invalid-feedback
        v-if="$v.formData.service_name.$anyError"
        v-html="getFormErrorsAsText('formData.service_name')"
      />
    </b-form-group>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import MixinFormValidate from "@/mixins/MixinFormValidate";
import BaseInput from "@/components/forms/BaseInput";

export default {
  name: 'dead-message-subscription-form',
  components: {
    BaseInput,
  },
  mixins: [
    MixinFormValidate,
  ],
  data() {
    return {
      formData: {
        id: null,
        email: '',
        topic: '',
        service_name: '',
      },
    };
  },
  computed: {
    formErrors() {
      return {
        formData: {
          email: {
            required: this.$vDict('errors.email_required.text'),
            email: this.$vDict('errors.email_not_valid.text'),
          },
          topic: {
            isValid: this.$vDict('dead_message_subscriptions.topic_or_service_name_is_required.text'),
          },
          service_name: {
            isValid: this.$vDict('dead_message_subscriptions.topic_or_service_name_is_required.text'),
          },
        }
      };
    },
  },
  validations() {
    const isTopicOrServiceName = () => !!(this.formData.topic || this.formData.service_name);

    return {
      formData: {
        email: {
          required,
          email,
        },
        service_name: {
          isValid: isTopicOrServiceName,
        },
        topic: {
          isValid: isTopicOrServiceName,
        },
      },
    };
  },
  methods: {
    patchFormData(data = {}) {
      Object.assign(this.formData, data);
    },
    setFormData(formData = {}) {
      this.formData = _.cloneDeep(formData);
    },
    getFormData() {
      return this.formData;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

import Vue from 'vue';

export function prepareAdministrativeUnits(adUnits = []) {
  return adUnits.map(adUnit => {
    const childrens = (adUnit.items || []);
    // let info;

    // switch (adUnit.mark) {
    //   case 1:
    //     info = {
    //       variant: 'warning',
    //       title: Vue.prototype.$vDict('forms.administrative_unit_deprecated_text.text'),
    //     };
    //     break;
    //   case 2:
    //     info = {
    //       type: 'label',
    //       variant: 'secondary',
    //       title: Vue.prototype.$vDict('forms.administrative_unit_new_label.text'),
    //     };
    //     break;
    //   default:
    // }

    return {
      ...adUnit,
      title: `${adUnit.title}`,
      items: prepareAdministrativeUnits(childrens),
      // info,
    };
  });
}

import {
  getTagsList,
  postTag,
  patchTag,
  deleteTag,
} from "@/api/repositories/userTagsRepository";
import { IndexedArray } from "@/shared/proxies";

const initialState = () => ({
  userTags: undefined,
});

export default {
  state: initialState,
  getters: {
    getUserTags(state) {
      return state.userTags;
    },
    getUserTag: (state) => (id) => {
      return state.userTags ? state.userTags.findById(id) : null;
    }
  },
  mutations: {
    SET_USER_TAGS(state, tags = []) {
      state.userTags = new IndexedArray(tags);
    },
    DELETE_USER_TAG(state, tagId) {
      if (!state.userTags) {
        return;
      }

      const tags = state.userTags.filter(el => el.id !== tagId);
      state.userTags = new IndexedArray(tags);
    },
    ADD_USER_TAG(state, tag = {}) {
      if (!state.userTags) {
        return;
      }

      state.userTags.push(tag);
    },
    UPDATE_USER_TAG(state, payload) {
      if (!state.userTags) {
        return;
      }

      const { tagId, tag } = payload;
      state.userTags.updateById(tagId, tag);
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
  },
  actions: {
    async fetchUserTags({ state, commit },) {
      const query = {};

      if (state.userTags) {
        return state.userTags;
      }

      let tags = [];

      try {
        const response = await getTagsList(query);
        tags = response?.data || [];

        commit('SET_USER_TAGS', tags);
      } catch (e) {}

      return tags;
    },
    async addUserTag({ state, commit }, tag = {}) {
      let addedTag;

      try {
        const response = await postTag(tag);

        addedTag = response?.data;

        if (addedTag) {
          commit('ADD_USER_TAG', addedTag);
        }
      } catch (e) {
        return Promise.reject(e);
      }

      return addedTag;
    },
    async updateUserTag({ state, commit }, payload = {}) {
      const { tagId, tag } = payload;

      try {
        const response = await patchTag(tagId, tag);

        if (response) {
          commit('UPDATE_USER_TAG', { tagId, tag });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteUserTag({ state, commit }, tagId) {
      try {
        const response = await deleteTag(tagId);

        if (response) {
          commit('DELETE_USER_TAG', tagId);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  namespaced: true,
};

import Vue from 'vue';
import { getAuthOpid } from "@/shared/account";

// @vue/component
export const updateAppMixin = Vue.extend({
  name: 'update-app-mixin',
  data() {
    return {
      updateExists: false,
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', this.onWorkBoxWaiting);
      this.$workbox.addEventListener('externalwaiting', this.onWorkBoxWaiting);
    }
  },
  methods: {
    onWorkBoxWaiting() {
      this.updateExists = true;
    },
    async acceptUpdateApp() {
      this.updateExists = false;
      this.$workbox.messageSkipWaiting();
    },
  },
});

// @vue/component
export const checkActualTabAppMixin = Vue.extend({
  name: 'check-actual-tab-app-mixin',
  data() {
    return {
      isTabActual: true,
      tabId: null,
    };
  },
  computed: {
    actualTabsId() {
      const opid = getAuthOpid();

      return _.compact([
        this.$store.getters['Account/userId'],
        +this.$store.getters['Account/getSettingsValue']('user.is_dummy'),
        this.$store.getters['Account/getSettingsValue']('user.language_id'),
        opid,
      ]).join('.');
    },
    hasQueryToken() {
      return _.has(this.$route.query, 'token');
    },
  },
  watch: {
    actualTabsId() {
      if (this.tabId === this.actualTabsId) {
        this.isTabActual = true;
        return;
      }

      const isPageHidden = this.checkVisiblePage();

      if (isPageHidden) {
        this.isTabActual = false;
      } else {
        this.tabId = this.actualTabsId;
      }
    },
  },
  mounted() {
    this.tabId = this.actualTabsId;

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState !== 'visible' || this.isTabActual) {
        return;
      }

      if (this.hasQueryToken) {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    });
  },
  methods: {
    checkVisiblePage() {
      let hidden = '';

      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
      }

      return hidden ? document[hidden] : false;
    },
  },
});

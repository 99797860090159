<script>
export default {
  data() {
    return {
      isMobile: false,
      mobBreakpoint: 767.98,
      // mql: null,
    };
  },
  // mounted() {
  //   this.mql = window.matchMedia(`(max-width: ${this.mobBreakpoint}px)`);
  //   this.mql.onchange = (e) => {
  //     this.isMobile = e.matches;
  //   };
  //
  //   this.isMobile = this.mql.matches;
  // },
  mounted() {
    this.checkMobile();

    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  updated() {
    this.checkMobile();
  },
  methods: {
    checkMobile() {
      this.isMobile = window.matchMedia(`(max-width: ${this.mobBreakpoint}px)`).matches;
    },
  },
};
</script>

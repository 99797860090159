import enums from '@/config/enums';

const { DateTime } = require('luxon');

export function getPeriodDaysDate(typeId) {
  const periodDays = _.get(enums.US_DATE_RANGE_TYPES.properties, `${typeId}.periodDays`);
  let res = null;

  if (periodDays) {
    const yesterday = DateTime.now().minus({ days: 1 });

    res = {
      gte: yesterday.minus({ days: periodDays }).startOf('day').toSQL({ includeOffset: false }),
      lte: yesterday.endOf('day').toSQL({ includeOffset: false }),
    };
  }

  return res;
}

export default {
  getPeriodDaysDate,
};

import { hasPageBlockers } from "@/shared/dom";
import { notificationsQueuePriority } from "@/config/notifications";

let timerInProgress = false;

function startTimingToShow(showFunc, payload = {}) {
  const { isNext, delay } = payload;
  const timeoutTime = delay ?? (isNext ? 1000 : 5000);
  const timeoutId = setTimeout(() => {
    if (hasPageBlockers()) {
      startTimingToShow(showFunc, payload);
    } else {
      showFunc();
      clearTimeout(timeoutId);
      timerInProgress = false;
    }
  }, timeoutTime);
}

export default {
  state: {
    shown: [],
    queue: [],
    readyToShow: null,
  },
  getters: {
    isNotifReady: state => (notifKey) => {
      return state.readyToShow === notifKey;
    },
  },
  mutations: {
    ADD_NOTIF(state, notif) {
      state.queue.push(notif);
      state.queue.sort((a, b) =>
        notificationsQueuePriority.findIndex(key => a.key.includes(key)) - notificationsQueuePriority.findIndex(key => b.key.includes(key)));
    },
    MOVE_TO_READY(state) {
      if (!state.queue.length) {
        return;
      }

      const notif = state.queue.shift();
      state.readyToShow = notif.key;
    },
    MOVE_TO_SHOWN(state) {
      state.shown.push(state.readyToShow);
      state.readyToShow = null;
    },
    REMOVE_SHOWN(state, notifKey) {
      state.shown = state.shown.filter(key => key !== notifKey);
    },
    RESET_READY_TO_SHOW(state) {
      state.readyToShow = null;
    },
  },
  actions: {
    addNotif({ state, dispatch, commit }, notif = {}) {
      if (state.readyToShow === notif.key) {
        commit('RESET_READY_TO_SHOW');
      }

      const notifDisabled = window.localStorage.getItem(`disableNotification(${notif.key})`) === 'true';

      if (state.queue.some(el => el.key === notif.key) ||
        state.shown.includes(notif.key) ||
        notifDisabled) {
        return;
      }

      // const queueLength = state.queue.length;

      commit('ADD_NOTIF', notif);

      if (!timerInProgress) {
        dispatch('startTiming');
      }
    },
    startTiming({ state, commit }, payload = {}) {
      if (timerInProgress || !state.queue.length) {
        return;
      }

      const { isNext } = payload;
      const nextNotif = state.queue[0];

      timerInProgress = true;

      startTimingToShow(() => {
        commit('MOVE_TO_READY');
      }, {
        isNext,
        delay: nextNotif?.delay,
      });
    },
    moveToShown({ state, commit, dispatch }, notifKey) {
      if (state.readyToShow !== notifKey) {
        return;
      }

      commit('MOVE_TO_SHOWN');

      if (state.queue.length > 0) {
        dispatch('startTiming', { isNext: true });
      }
    },
  },
  namespaced: true,
};

import CreatePersistedState from 'vuex-persistedstate';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

function encrypt(value = {}) {
  return aes.encrypt(JSON.stringify(value), process.env.VUE_APP_ENCRYPTION_SECRET).toString();
}

function decrypt(encryptText = '') {
  const decodedData = aes.decrypt(encryptText, process.env.VUE_APP_ENCRYPTION_SECRET).toString(enc);
  let res = '';

  try {
    res = JSON.parse(decodedData);
  } catch (e) {
    throw new Error('Could not parse JSON');
  }

  return res;
}

function getStorage(storageDriver = window.localStorage) {
  return {
    getItem: key => {
      const value = storageDriver.getItem(key);
      return decrypt(value);
    },
    setItem: (key, value) => {
      const res = encrypt(value);
      storageDriver.setItem(key, res);
    },
    removeItem: key => storageDriver.removeItem(key),
  };
}

function getMutations(storeModules = {}) {
  return Object.entries(storeModules).reduce((acum, [moduleName, module]) => {
    const mutationsKeys = Object.keys(module.mutations || {})
      .map(mutationKey => (module.namespaced ? `${moduleName}/${mutationKey}` : mutationKey));
    return [
      ...acum,
      ...mutationsKeys,
    ];
  }, []);
}

export function createPersistedStorage(options = {}) {
  const {
    storageDriver,
    storeModules = {},
    persistModules = [],
  } = options;
  const modules = persistModules.reduce((acum, moduleName) => {
    const module = storeModules[moduleName];
    return module ? {
      ...acum,
      [moduleName]: module,
    } : acum;
  }, {});
  const mutations = getMutations(modules);

  return new CreatePersistedState({
    key: 'PersistedStorage',
    storage: getStorage(storageDriver),
    filter: (mutation) => {
      return mutations.includes(mutation.type);
    },
    reducer: (state) => {
      return persistModules.reduce((acum, moduleName) => ({
        ...acum,
        [moduleName]: state[moduleName],
      }), {});
    },
  });
}

/*
  Base list
 */

import Vue from 'vue';
import store from "@/store";
import {
  ENTITY_LIST_VIEWS,
  CUSTOM_FIELD_TYPES,
} from "@/config/enums";
import { getGuid, isEmptyValue, updateObjectById, wait, mergeQueryParams } from "@/shared/utils";
import { DateTime } from "luxon";
import { getIndexedArray, IndexedArray } from "@/shared/proxies";
import _ from "lodash";
import BaseEntity from "@/shared/classes/entity/BaseEntity";
import router from "@/router";

const initialConfig = () => ({
  isMain: false,
  isSavePosition: false,
  exportToFile: true,
  print: true,
  showFieldsSettings: false,
  showMapView: false,
  couldHasCustomFields: false,
  defaultSort: {
    default: {},
  },
  defaultSearchFilter: {
    default: {},
  },
  defaultListFilter: {
    default: {},
  },
});

const FieldsIndexedArray = getIndexedArray('key');

export default class BaseList {
  static entityClass = BaseEntity;
  static isUserSection = false;

  constructor(listTypeId, configsFunc) {
    let config = {};

    if (typeof configsFunc === 'function') {
      const configs = configsFunc(listTypeId);
      config = configs[listTypeId] || {};
    }

    this.config = Object.assign(initialConfig(), config);
    this.defaultListFilter = {};
    this.defaultSearchFilter = {};
    this.defaultSort = {};

    // ids
    this.listTypeId = listTypeId;
    this.statusId = undefined;

    // view mode
    this.viewMode = ENTITY_LIST_VIEWS.LIST;

    // result data
    this.items = [];
    this.aggs = {}; // aggregation data from meta

    // current fields for display in list
    this.requiredCurrentFields = [];
    this.currentFields = [];

    // current values
    this.currentSort = {};
    this.currentPage = 1;

    // totals
    this.total = undefined; // total items with required filters
    this.allTotal = undefined; // all total items with only permanent filters
    this.lockedTotal = undefined; // total of locked items

    // filters from UI
    this.listFilter = {};
    this.searchFilter = {};

    // props for query
    this.permanentQueryData = {}; // affect to allTotal value (only for filters or query params)
    this.requiredQueryData = {}; // not affect to allTotal value (additional filter or sort, aggs etc...)

    // pages
    this.perPage = Number(process.env.VUE_APP_ITEMS_PER_PAGE);
    this.offsetIndex = 0;

    // states
    this.isLoadingItems = false;
    this.listDataInited = false;
    this.printing = false;
    this.selectedItems = [];
    this.isLoadingAllTotal = false;
    this.isLoadingAllTotalLongTime = false;
    this.isLoadingOperation = false;
    this.isShowLockedItems = store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'isShowLockedItems',
    });

    // filled flags
    this.isFiltersFilled = false;
    this.isSearchFilterFilled = false;
    this.isListFilterFilled = false;

    // prediction
    this.predictionGuid = null;
    this.predictionTotal = null;
    this.isLoadingPrediction = false;

    // for print
    this.printAdditionalFieldset = []; // add some fieldsets to request which need only for print version
    this.printFieldsBlacklist = []; // exclude some fields for print version
    this.printSettingsFields = []; // print settings fields
    this.fixedPrintFields = []; // for show permanent fields in print version

    // inheritance search filter for current list filter
    this.searchFilterInheritanceConfig = [];

    // fields
    this.fieldsSections = new FieldsIndexedArray([]); // sections with fields as children

    // filters setting
    this.listFiltersSetting = [];

    //
    this.sortLookups = [];
    this.dataset = {};
    this.loadingItems = [];
    this.settingsForLoadingDatasets = [];
    this.customFields = [];
    this.ssnCode = null;
    this.ssn = {};
    this.customFieldPrefix = 'custom_fields.CF_';
    this.lastQuery = null;

    // functions
    this.debouncedLoadPrediction = _.debounce(this.loadPredictionTotal, 1000);
    this.selfClass = BaseList;
  }

  static getLoadItemsFunc() {
    return this.isUserSection ?
      this.entityClass.loadUserItemsFunc :
      this.entityClass.loadAllItemsFunc;
  }

  async getMainInstance() {
    return this;
  }

  getFixedPrintFields() { return []; }

  initDefaultValues() {
    this.defaultListFilter = this.getDefaultConfigValue('defaultListFilter');
    this.defaultSearchFilter = this.getDefaultConfigValue('defaultSearchFilter');
    this.defaultSort = this.getDefaultConfigValue('defaultSort');
  }

  getPermanentQueryData() {
    const query = mergeQueryParams(this.getDefaultConfigValue('permanentQueryData'), this.permanentQueryData);

    if (this.config.hasLockedItems && this.isShowLockedItems && this.viewMode !== ENTITY_LIST_VIEWS.MAP) {
      query.show_locked = 1;
    }

    if (this.statusId === 'all' && router.currentRoute.query.disableContext !== '1') {
      Object.assign(query, {
        context: 'client',
      });
    }

    return query;
  }

  onClearFilters() {}

  beforeLoadingItems() {}

  afterLoadingItems() {}

  afterDeleteHandle(itemId) {}

  prepareItems(items, queryFields) {
    return Promise.resolve(new IndexedArray(items));
  }

  async loadDatasets() {}

  async onErrorLoadingItems() {}

  async onSuccessLoadingItems() {}

  async loadItems(...args) {
    return this.loadListItems(...args);
  }

  // getters
  getDefaultConfigValue(key = '', def = {}) {
    const byStatus = this.statusId ? _.get(this.config, `${key}.byStatus.${this.statusId}`) : null;
    const res = byStatus || _.get(this.config, `${key}.default`) || def;
    return _.cloneDeep(res);
  }

  getAllCurrentFields() {
    return _.unionBy([...this.requiredCurrentFields, ...this.currentFields], 'key');
  }

  getCurrentSort() {
    return this.config.isOnlyOneSort ? this.currentSort : _.defaults({}, this.currentSort, this.defaultSort);
  }

  getQueryFields() {
    const listFields = this.getAllCurrentFields();
    let queryFields = listFields.reduce((acum, field) => {
      return _.union(acum, field.fieldset);
    }, []);

    if (!this.printing) {
      return queryFields;
    }

    if (this.fixedPrintFields.length) {
      this.fixedPrintFields.forEach(field => {
        queryFields = _.union(queryFields, field.fieldset);
      });
    }

    if (this.printAdditionalFieldset) {
      queryFields = _.union(queryFields, this.printAdditionalFieldset);
    }

    if (this.printSettingsFields.length) {
      this.printSettingsFields.forEach(field => {
        queryFields = _.union(queryFields, field.fieldset);
      });
    }

    return queryFields;
  }

  getSortValue(sortKey, sortValue) {
    const lSort = this.sortLookups.find(el => el.sortKey === sortKey);
    const sortDesc = sortValue.order === 'd';

    if (!lSort) {
      return {
        order: sortDesc ? 'd' : 'a',
      };
    }

    const lSortIds = Vue.prototype.$lDict(lSort.lookupKey, { attr: 'sort' });
    let listSort = sortDesc ? _.reverse([...lSortIds]) : lSortIds;

    if (lSort.dynamicValuesBefore || lSort.dynamicValuesAfter) {
      const dynamicValuesBefore = lSort.dynamicValuesBefore || [];
      const dynamicValuesAfter = lSort.dynamicValuesAfter || [];

      listSort = sortDesc ?
        [...dynamicValuesBefore, ...listSort, ...dynamicValuesAfter] :
        [...dynamicValuesAfter, ...listSort, ...dynamicValuesBefore];
    }

    return {
      order: 'c',
      list: listSort,
    };
  }

  getAllSortableFields() {
    const fields = this.getAllCurrentFields();
    return fields.filter(field => field.sortable);
  }

  getSortValues(sortKey, sortValue) {
    const sortableFields = this.getAllSortableFields();
    const field = sortableFields.find(el => el.key === sortKey);

    if (!field) {
      return {
        [sortKey]: sortValue,
      };
    }

    let res = {};

    const fieldSortKeys = field.sortKeys || [field.key];

    fieldSortKeys.forEach(fieldSortKey => {
      const customFieldTypeId = field?.data?.custom_field_type_id;

      res[fieldSortKey] = this.getSortValue(fieldSortKey, sortValue);

      if (customFieldTypeId === CUSTOM_FIELD_TYPES.NUMBER ||
        customFieldTypeId === CUSTOM_FIELD_TYPES.PERCENT ||
        customFieldTypeId === CUSTOM_FIELD_TYPES.FORMULA) {
        Object.assign(res[fieldSortKey], {
          type: 'number',
        });
      }
    });

    return res;
  }

  getQuerySort() {
    const currentSort = this.getCurrentSort();
    const requiredSort = this.requiredQueryData.sort || {};
    const resSort = {};

    Object.entries(currentSort).forEach(([key, val]) => {
      const sortValues = this.getSortValues(key, val);
      Object.assign(resSort, sortValues);
    });

    return { ...resSort, ...requiredSort };
  }

  getAttachmentsFilter(jsFilter = {}) {
    let attachmentFields = this.customFields.filter(customField =>
      customField.custom_field_type_id === CUSTOM_FIELD_TYPES.ATTACHMENT &&
      customField.show_in_filter
    );
    const hasAttachmentFields = attachmentFields.length > 0;
    const hasAttachmentFilter = jsFilter.cf_attachments_search || jsFilter.hasOwnProperty('cf_attachments_size');

    if (!hasAttachmentFields || !hasAttachmentFilter) {
      return {};
    }

    const res = {};
    const {
      cf_attachments_search,
      cf_attachments_size,
      cf_attachments_fields,
    } = jsFilter;

    if (cf_attachments_fields && cf_attachments_fields.length > 0) {
      attachmentFields = attachmentFields.filter(field => cf_attachments_fields.includes(field.id));
    }

    if (cf_attachments_size === 0) {
      const filterKey = attachmentFields.reduce((acum, field) => {
        return [...acum, `${this.customFieldPrefix}${field.id}_size`];
      }, []).join('||');
      res[filterKey] = {
        eq: 0,
      };
    } else if (cf_attachments_search) {
      const filterKey = attachmentFields.reduce((acum, field) => {
        return [...acum, `${this.customFieldPrefix}${field.id}_search`];
      }, []).join('||');
      res[filterKey] = {
        q: cf_attachments_search,
      };
    } else if (cf_attachments_size === 1) {
      const filterKey = attachmentFields.reduce((acum, field) => {
        return [...acum, `${this.customFieldPrefix}${field.id}_size`];
      }, []).join('||');
      res[filterKey] = {
        gte: 0,
      };
    }

    return res;
  }

  getCustomFieldFilter(filterKey, filterValue) {
    const customFieldId = Number(filterKey.replace(this.customFieldPrefix, ''));
    const customField = this.customFields.find(el => el.id === customFieldId);
    const customFieldTypeId = customField?.custom_field_type_id;
    let res = {};

    switch (customFieldTypeId) {
      case CUSTOM_FIELD_TYPES.SINGLE_LINE_TEXT:
      case CUSTOM_FIELD_TYPES.DESCRIPTION:
      case CUSTOM_FIELD_TYPES.PHONE_NUMBER:
      case CUSTOM_FIELD_TYPES.EMAIL:
        res = {
          q: filterValue,
        };
        break;
      case CUSTOM_FIELD_TYPES.NUMBER:
      case CUSTOM_FIELD_TYPES.PERCENT:
      case CUSTOM_FIELD_TYPES.FORMULA:
        res = {};

        ['gte', 'lte'].forEach(qKey => {
          if (filterValue[qKey]) {
            res[qKey] = filterValue[qKey];
          }
        });
        break;
      case CUSTOM_FIELD_TYPES.DATE:
        res = {};

        ['gte', 'lte'].forEach(qKey => {
          if (filterValue[qKey]) {
            const date = filterValue[qKey]?.isLuxonDateTime ? filterValue[qKey] : DateTime.fromISO(filterValue[qKey]);
            res[qKey] = date.toISODate();
          }
        });
        break;
      case CUSTOM_FIELD_TYPES.MULTIPLE_SELECT:
      case CUSTOM_FIELD_TYPES.SINGLE_SELECT:
        res = {
          in: Array.isArray(filterValue) ? filterValue : [filterValue],
        };
        break;
      case CUSTOM_FIELD_TYPES.CHECKBOX:
        if (filterValue) {
          res = { in: [1] };
        } else {
          res = { not_in: [1] };
        }
        break;
      default:
    }

    if (customFieldTypeId === CUSTOM_FIELD_TYPES.NUMBER ||
      customFieldTypeId === CUSTOM_FIELD_TYPES.PERCENT ||
      customFieldTypeId === CUSTOM_FIELD_TYPES.FORMULA) {
      Object.assign(res, {
        type: 'number',
      });
    }

    return res;
  }

  initUsersNestedFilter() {}

  prepareJSFilter(jsFilter) {
    return jsFilter;
  }

  getQueryFilters(filters = {}, options = {}) {
    const opts = _.defaults(options, { ssn: true });
    const isCheckSSN = this.config?.showSsn && opts.ssn;
    const selectedSearch = store.getters.getSelectedSearch(this.listTypeId);
    const ssnCode = this.ssnCode || selectedSearch?.ssn;

    if (isCheckSSN && ssnCode) {
      return {
        ssn: ssnCode,
      };
    }

    const permanentQuery = this.getPermanentQueryData();
    const queryData = mergeQueryParams(permanentQuery, this.requiredQueryData);
    const jsFilter = queryData.jsFilter || {};
    const searchFilters = Object.assign({
      searchFilter: this.searchFilter,
      listFilter: this.listFilter,
    }, filters);
    const plainSearchFilters = Object.values(searchFilters).reduce((acum, filter) => ({ ...acum, ...filter }), {});
    const customFieldsFilter = this.getAttachmentsFilter(plainSearchFilters);
    let usersNestedFilter;

    if (this.statusId !== undefined) {
      usersNestedFilter = this.initUsersNestedFilter(plainSearchFilters);
    }

    Object.entries(plainSearchFilters).forEach(([filterKey, filterValue]) => {
      const filterSetting = this.listFiltersSetting.find(el => el.key === filterKey);

      if (filterSetting?.convertResultToQuery) {
        const filters = filterSetting.convertResultToQuery(filterValue) || {};
        Object.assign(jsFilter, filters);
      } else if (filterKey.indexOf(this.customFieldPrefix) !== -1) {
        customFieldsFilter[filterKey] = this.getCustomFieldFilter(filterKey, filterValue);
      } else {
        jsFilter[filterKey] = filterValue;
      }
    });

    const queryFilter = {
      ...queryData.filter,
      ...customFieldsFilter,
    };

    if (usersNestedFilter) {
      queryFilter.users = usersNestedFilter;
    }

    return {
      filter: queryFilter,
      jsFilter: this.prepareJSFilter(jsFilter),
    };
  }

  getAllTotalQuery(query = {}) {
    const permanentQuery = this.getPermanentQueryData();
    const affectFilters = this.getAffectTotalFilters();

    return mergeQueryParams(permanentQuery, affectFilters, {
      limit: 0,
    }, query);
  }

  getAffectTotalFilters() {
    const queryFilter = this.getQueryFilters();
    const affectKeys = this.getDefaultConfigValue('affectTotalFilterKeys', []);
    const filters = {};

    affectKeys.forEach(affectKey => {
      Object.entries(queryFilter).forEach(([qfKey, filter]) => {
        if (_.has(filter, affectKey)) {
          filters[qfKey] = Object.assign({}, filters[qfKey], {
            [affectKey]: filter[affectKey],
          });
        }
      });
    });

    return filters;
  }

  getQueryPrediction(filters = {}, options = {}) {
    const permanentQuery = this.getPermanentQueryData();
    const queryFilters = this.getQueryFilters(filters, options);

    return mergeQueryParams(permanentQuery, queryFilters, {
      sort: this.getQuerySort(),
      limit: 0,
    });
  }

  getQuery(filters = {}) {
    const permanentQuery = this.getPermanentQueryData();
    const queryFilters = this.getQueryFilters(filters);
    const offset = (this.currentPage - 1) * this.perPage;
    const fieldsQueryParams = this.currentFields.reduce((acum, field) => {
      const fieldQueryParams = field.queryParams || {};
      const queryParams = {};
      const section = this.fieldsSections.findById(field.sectionKey);

      if (section?.filters) {
        section.filters.forEach(filter => {
          const filterValue = field[filter.fieldKey];

          if (!isEmptyValue(filterValue)) {
            queryParams[filter.queryKey] = filterValue;
          }
        });
      }

      return {
        ...acum,
        ...queryParams,
        ...fieldQueryParams,
      };
    }, {});
    const queryData = mergeQueryParams(
      permanentQuery,
      this.requiredQueryData,
      queryFilters,
      fieldsQueryParams,
    );

    return {
      ...queryData,
      offset,
      fields: this.getQueryFields(),
      sort: this.getQuerySort(),
      limit: this.perPage,
    };
  }

  // setters
  setCurrentPage(page = 1) {
    this.currentPage = page;

    if (this.config.isSavePosition) {
      store.commit('SET_LIST_VALUE', {
        listTypeId: this.listTypeId,
        key: 'currentPage',
        value: page,
      });
      store.commit('SET_LIST_VALUE', {
        listTypeId: this.listTypeId,
        key: 'statusId',
        value: this.statusId,
      });
    }
  }

  setViewMode(viewMode) {
    this.viewMode = viewMode;

    // if (this.config.isSavePosition) {
      store.commit('SET_LIST_VALUE', {
        listTypeId: this.listTypeId,
        key: 'viewMode',
        value: viewMode,
      });
    // }
  }

  setPerPage(perPage) {
    this.perPage = perPage;
  }

  setSort(sort = {}, _store = true) {
    if (_store) {
      store.commit('SET_LIST_SORT', {
        listTypeId: this.listTypeId,
        key: 'sort',
        value: sort,
        statusId: this.statusId,
      });
    }

    this.currentSort = _.cloneDeep(sort);
  }

  onChangeSearchFilter() {}

  setSearchFilter(searchFilter= {}, _store = true) {
    if (_store) {
      store.commit('SET_LIST_VALUE', {
        listTypeId: this.listTypeId,
        key: 'searchFilter',
        value: searchFilter,
      });
    }

    this.searchFilter = _.cloneDeep(searchFilter);
    this.onChangeSearchFilter();
  }

  setListFilter(filter = {}, _store = true) {
    if (_store) {
      store.commit('SET_LIST_FILTER', {
        listTypeId: this.listTypeId,
        statusId: this.statusId,
        value: filter,
      });
    }

    this.listFilter = _.cloneDeep(filter);
  }

  setDataFromMeta(meta = {}) {
    this.total = meta.total || 0;
    this.lockedTotal = meta.locked_total || 0;
    this.aggs = meta.aggs || {};
    this.ssn = meta.ssn || {};
    this.offsetIndex = meta.offset || 0;
    this.allTotal = meta.all_total || this.total;
  }

  // init methods
  initListFilter(filter) {
    if (filter) {
      this.setListFilter(filter);
      return;
    }

    const storedListFilter = store.getters.getListFilter({
      listTypeId: this.listTypeId,
      statusId: this.statusId,
    });
    const listFilter = storedListFilter || this.defaultListFilter;

    this.listFilter = _.cloneDeep(listFilter);
  }

  initSearchFilter(filter) {
    if (filter) {
      this.setSearchFilter(filter);
      return;
    }

    const storedSearchFilter = store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'searchFilter',
    });
    const searchFilter = storedSearchFilter || this.defaultSearchFilter;

    this.searchFilter = _.cloneDeep(searchFilter);
  }

  initCurrentSort() {
    const storedSort = store.getters.getListSort({
      listTypeId: this.listTypeId,
      statusId: this.statusId,
    });

    if (storedSort) {
      this.currentSort = storedSort;
      return;
    }

    this.currentSort = _.cloneDeep(this.defaultSort);
  }

  initSavedViewMode() {
    const viewMode = store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'viewMode',
    });

    if (viewMode !== undefined) {
      this.viewMode = viewMode;
    }
  }

  initSavedUserPosition() {
    // current page
    const currentPage = store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'currentPage',
    });
    const statusId = store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'statusId',
    });

    // console.log('statusId - statusId', statusId, this.statusId);

    if (statusId === this.statusId && currentPage !== undefined) {
      this.currentPage = currentPage;
    }

    // status id
    // const statusId = store.getters.getEntityListValue({
    //   listTypeId: this.listTypeId,
    //   key: 'statusId',
    // });
    //
    // if (statusId !== undefined) {
    //   this.statusId = statusId;
    // }
  }

  async initListData() {
    this.initListFilter();
    this.initSearchFilter();
    this.initCurrentSort();
    this.listDataInited = true;
  }

  // store methods
  saveLastQuery(value = {}) {
    store.commit('SET_LIST_VALUE', {
      listTypeId: this.listTypeId,
      key: 'lastQuery',
      value,
    });
  }

  getLastQuery() {
    return store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'lastQuery',
    });
  }

  async clearFilters() {
    if (store.getters.getSelectedSearch(this.listTypeId)) {
      store.commit('DELETE_USER_SELECTED_SEARCH', { listTypeId: this.listTypeId });
      await wait(500);
    }

    this.setListFilter({});
    this.setSearchFilter({});
    this.onClearFilters();
    this.loadItems(1);
  }

  resetPredictionData() {
    this.predictionGuid = null;
    this.predictionTotal = null;
    this.isLoadingPrediction = false;
  }

  async loadAllTotal() {
    const query = this.getAllTotalQuery();
    let allTotal = this.allTotal;
    let seconds = 0;

    const secondsTimer = setInterval(() => {
      seconds++;

      if (seconds > 5) {
        this.isLoadingAllTotalLongTime = true;
      }
    }, 1000);
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();

    this.isLoadingAllTotal = true;

    try {
      const response = await loadItemsFunc(query);
      allTotal = response?.meta?.total ?? 0;
    } catch (error) {
      console.log('Error loadAllTotal', error);
    }

    clearInterval(secondsTimer);
    this.isLoadingAllTotal = false;
    this.isLoadingAllTotalLongTime = false;

    return allTotal;
  }

  async loadPrediction(filters = {}, options = {}) {
    const query = this.getQueryPrediction(filters, options);
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();
    let response = {};

    try {
      response = await loadItemsFunc(query);
    } catch (error) {
      console.log(error);
    }

    return Promise.resolve(response);
  }

  async loadPredictionTotal(filters = {}) {
    const guid = getGuid();
    let response;

    this.predictionGuid = guid;
    this.isLoadingPrediction = true;

    try {
      response = await this.loadPrediction(filters);
    } catch (error) {
      console.log(error);
    }

    if (this.predictionGuid === guid) {
      this.predictionTotal = response?.meta?.total || 0;
      this.isLoadingPrediction = false;
    }
  }

  async loadMetaData(meta = {}) {
    const listQuery = this.getLastQuery();
    const query = {
      ...listQuery,
      limit: 0,
    };
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();
    const resMeta = _.cloneDeep(meta);

    try {
      if (!resMeta.hasOwnProperty('total')) {
        await loadItemsFunc(query).then(response => {
          Object.assign(resMeta, {
            ...(response?.meta || {}),
          });
        });
      }

      this.setDataFromMeta(resMeta);

      if (this.config.hasLockedItems && !this.printing && !resMeta.hasOwnProperty('locked_total')) {
        this.loadLockedTotal(query).then(lockedTotal => {
          this.lockedTotal = lockedTotal;
        });
      }

      if (!this.printing && !resMeta.hasOwnProperty('all_total')) {
        this.loadAllTotal().then(allTotal => {
          this.allTotal = allTotal;
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  initSearchFilled() {
    this.isSearchFilterFilled = !_.isEmpty(this.searchFilter);
    this.isListFilterFilled = !_.isEmpty(this.listFilter);
    this.isFiltersFilled = this.isSearchFilterFilled || this.isListFilterFilled;
  }

  async loadAdditionalValues(items, queryFieldsKeys, currentFields) {}

  async loadListItems(page = null, queryParams = {}, filters = {}) {
    if (page) {
      this.setCurrentPage(page);
    }

    this.selectedItems = [];
    this.initSearchFilled();
    this.beforeLoadingItems();

    const listQuery = this.getQuery(filters);
    const query = {
      ...listQuery,
      ...queryParams,
    };

    const loadItemsFunc = this.selfClass.getLoadItemsFunc();

    this.isLoadingItems = true;
    this.items = [];

    try {
      const response = await loadItemsFunc(query);
      const meta = response?.meta || {};
      const items = response?.data || [];

      this.saveLastQuery(query);
      this.setDataFromMeta(meta);
      this.loadMetaData(meta);
      this.items = await this.prepareItems(items, query.fields);

      this.loadDatasetsBySettings(this.items);
      this.loadDatasets(items, query.fields);
      this.afterLoadingItems();
      await this.onSuccessLoadingItems();
    } catch (error) {
      await this.onErrorLoadingItems(error);
    }

    this.isLoadingItems = false;
  }

  async loadItemsAfterDelete(offset = 0, limit = 1) {
    const listQuery = store.getters.getEntityListValue({
      listTypeId: this.listTypeId,
      key: 'lastQuery',
    });

    if (!listQuery) {
      return;
    }

    const query = {
      ...listQuery,
      offset,
      limit,
    };
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();

    try {
      const response = await loadItemsFunc(query);
      const items = await this.prepareItems(response?.data || [], query.fields);

      this.items = new IndexedArray(this.items.concat(items));
      this.loadDatasetsBySettings(this.items);

      // load dataset
      this.loadDatasets(items, query.fields);
    } catch (error) {}
  }

  async deleteItem(entityId) {
    const leftItems = this.items.filter(item => item.id !== entityId);
    const quantityFromBeginning = this.currentPage * this.perPage;

    if (!leftItems.length) {
      const page = quantityFromBeginning < this.total || this.currentPage === 1 ?
        this.currentPage :
        this.currentPage - 1;

      await this.loadListItems(page);
      return;
    }

    this.items = new IndexedArray(leftItems);

    if (this.selectedItems.length > 0) {
      this.selectedItems = this.selectedItems.filter(itemId => itemId !== entityId);
    }

    const missingQuantityOnPage = this.perPage - this.items.length;
    const offset = quantityFromBeginning - missingQuantityOnPage;

    await Promise.all([
      this.loadItemsAfterDelete(offset, missingQuantityOnPage),
      this.loadMetaData(),
    ]);
  }

  updateItem(entityId, data) {
    const item = this.items.findById(entityId);
    item.patchData(data);
  }

  async reqDeleteItem(itemId) {
    const { deleteItemFunc } = this.selfClass.entityClass;
    let res = false;

    if (!deleteItemFunc) {
      return res;
    }

    this.loadingItems.push(itemId);

    try {
      res = await deleteItemFunc(itemId);
      if (res) {
        this.deleteItem(itemId);
      }
    } catch (error) {
      console.log(error);
    }

    this.loadingItems = this.loadingItems.filter(el => el !== itemId);

    return Promise.resolve(res);
  }

  loadDatasetsBySettings(items = []) {
    if (!this.settingsForLoadingDatasets.length) {
      return;
    }

    this.settingsForLoadingDatasets.forEach(setting => {
      const { fieldKeys, datasetKey, loadFunc, queryParams } = setting;
      const itemIds = items.reduce((acum, curr) => {
        const arrIds = fieldKeys.map(fieldKey => curr.type === 'entity' ? curr.getValue(fieldKey) : curr[fieldKey]);
        return _.union(acum, _.compact(_.flatten(arrIds)));
      }, []);

      if (itemIds.length > 0) {
        const _query = queryParams || {};
        const query = {
          ..._query,
          offset: 0,
          limit: itemIds.length,
          filter: {
            id: { in: itemIds },
          },
        };

        loadFunc(query)
          .then(response => {
            this.updateDataset(datasetKey, response?.data || []);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }

  updateDataset(key, items, idKey = 'id') {
    const datasetIndexedArray = getIndexedArray(idKey);
    const datasetItems = this.dataset[key] || new datasetIndexedArray([]);
    // const dataset = new datasetIndexedArray([
    //   ...datasetItems,
    //   ...items,
    // ]);

    items.forEach(item => {
      datasetItems.push(item);
    });

    // const data = items.reduce((acum, item) => ({
    //   ...acum,
    //   [item[idKey]]: item,
    // }), {});
    // const res = this.dataset[key] ? {
    //   ...this.dataset[key],
    //   ...data,
    // } : data;

    Vue.set(this.dataset, key, datasetItems);
  }

  getDatasetItem(key, itemId) {
    if (!this.dataset[key]) {
      return null;
    }

    const data = this.dataset[key];
    return data.findById(itemId);
  }

  onGoToEntity(itemId) {
    const itemIndex = this.items.findIndex(el => el.navigationId === itemId);

    store.commit('SET_LIST_VALUE', {
      listTypeId: this.listTypeId,
      key: 'navigationData',
      value: itemIndex !== -1 ? {
        offsetIndex: this.offsetIndex + itemIndex,
      } : null,
    });
  }

  getSelectedEntities() {
    const entities = this.selectedItems.map(entityId => this.items.findById(entityId));
    return new IndexedArray(entities);
  }

  onActionDone() {}

  async loadLockedTotal(query) {
    const loadItemsFunc = this.selfClass.getLoadItemsFunc();
    let lockedTotal = 0;

    try {
      const response = await loadItemsFunc({
        ...query,
        show_locked: 1,
        show_locked_total: 1,
        limit: 0,
      });
      lockedTotal = response?.meta?.locked_total || 0;
    } catch (error) {}

    return lockedTotal;
  }
}

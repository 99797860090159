import { Workbox } from 'workbox-window';

let wb;

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
  wb.addEventListener('controlling', () => {
    window.location.reload();
  });

  wb.register().then(reg => {
    setInterval(() => {
      reg.update().catch(() => {});
    }, 1000 * 60);
  });
} else {
  wb = null;
}

export default wb;

<script>
import { scrollToElement, scrollIntoCustomView } from "@/shared/utils";

export default {
  name: 'mixin-form-validate',
  data() {
    return {};
  },
  computed: {
    formErrors() {
      return {};
    },
  },
  methods: {
    waitForAsyncValidation() {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
          return resolve();
        }
        const poll = setInterval(() => {
          if (!this.$v.$pending) {
            clearInterval(poll);
            resolve();
          }
        }, 200);
      });
    },
    isInvalid(validations) {
      return validations.$dirty && validations.$invalid;
    },
    getFormErrors(field = 'formData') {
      const vForm = this.$v;
      const vField = field ? _.get(vForm, field) : vForm;
      const fieldError = field ? _.get(this.formErrors, field) : this.formErrors;
      let res = [];

      if (typeof fieldError === 'string') {
        res.push(fieldError);
      } else if (vField && this.isInvalid(vField) && fieldError) {
        Object.keys(vField.$params)
          .filter(pKey => _.has(fieldError, pKey))
          .forEach(pKey => {
            const fieldKey = field ? `${field}.${pKey}` : pKey;
            const error = _.get(this.formErrors, fieldKey);

            if (typeof error !== 'string' || (typeof error === 'string' && !vField[pKey])) {
              const errors = this.getFormErrors(fieldKey);
              res = res.concat(errors);
            }
          });
      }

      return res;
    },
    getFormErrorsAsText(field = 'formData') {
      return this.getFormErrors(field).join('<br>')
    },
    async validateForm(_config = {}) {
      const config = Object.assign({
        field: 'formData',
        toastErrors: true,
        scrollToError: true,
      }, _config);
      const validationForm = config.field ? _.get(this.$v, config.field) : this.$v;
      let res = true;

      if (validationForm) {
        validationForm.$reset();
        validationForm.$touch();
        await this.waitForAsyncValidation();

        if (validationForm.$invalid) {
          if (config.toastErrors) {
            const errorMsgs = config.field ? this.getFormErrors(config.field) : this.getFormErrors();
            this.$notifToastr('error', errorMsgs.join('<br>'));
          }

          res = false;
        }
      }

      if (config.scrollToError && !res) {
        const rootEl = config.mainEl ? config.mainEl : this.$root.$el;

        this.$nextTick( () => {
          const invalidElements = rootEl.querySelectorAll('.is-invalid');

            if (invalidElements.length > 0) {
              if (config.mainEl) {
                scrollIntoCustomView(invalidElements[0])
              } else {
                scrollToElement(invalidElements[0], {
                  topOffset: 70,
                  relativeDocument: true,
                });
              }
            }
        })
      }

      return Promise.resolve(res);
    },
    async validateEveryForm(formsArgs = []) {
      for (const formArgs of formsArgs) {
        if (!await this.validateForm(formArgs)) return false;
      }
      return true;
    },
    async validateForms(forms = [], toastErrors = true, iterate = false) {
      let res;

      if (iterate) {
        const formsArgs = forms.map(form => ({ field: form, toastErrors }));
        res = this.validateEveryForm(formsArgs);
      } else {
        const validates = forms.map(form => this.validateForm({
          field: form,
          toastErrors,
        }));
        const resAll = await Promise.all(validates);
        res = Promise.resolve(resAll.every(isValid => isValid));
      }

      return res;
    },
  },
};
</script>

import enums from '@/config/enums';
import vars from '@/config/vars';
import store from '@/store';
import Vue from 'vue';
import {
  findById,
  isEmptyValue,
  formatDateTime, convertOffsetsToDateRange,
} from "@/shared/utils";
import { formatBudgetRange } from "@/shared/formatters";
import { getResultTextFromDateValue } from "@/shared/result-texts";

const { DateTime } = require('luxon');

const formatDateMonth = 'yyyy LLLL';

export function getBadgeBudget(val) {
  const arrValues = [val?.gte, val?.lte];
  return formatBudgetRange(arrValues, {
    min: store.getters['Account/selectionsValueMin'],
    max: store.getters['Account/selectionsValueMax'],
  });
}

export function getWeekLabel(opt) {
  let res = null;

  if (opt) {
    const optParts = opt.split('-');
    const dateType = optParts[0];
    const dateValue = optParts[1];
    const dt = DateTime.now();
    let weekNumber = '';
    let weekText = '';

    switch (dateType) {
      case 'w':
        weekNumber = dt.plus({ weeks: -dateValue }).weekNumber;
        weekText = Vue.prototype.$vDict('global.text_week.text');
        res = `${weekText} ${weekNumber}`;
        break;

      case 'd':
        res = dateValue < dt.weekday ? dt.set({ weekday: dateValue }).setLocale(Vue.prototype.$getDictLanguage('intlIso')).toFormat('cccc') : Vue.prototype.$vDict('global.text_today.text');
        break;

      case 'today':
        res = Vue.prototype.$vDict('global.text_today.text');
        break;

      default:
    }
  }

  return res || Vue.prototype.$vDict('search.time_period_all.text');
}

export function getBadgeResponsibleContact(val, clientUsers = []) {
  let res = '';

  if (!val || val.length === 0) {
    return res;
  }

  if (val.length === clientUsers.length) {
    return Vue.prototype.$vDict('global.text_anyone.text');
  }

  if (val.length === 1 && clientUsers.length > 0) {
    const user = clientUsers.find(el => val.includes(el.id));
    res = user ? user.me_name || user.name || user.email : '';
  } else if (val.length > 0) {
    res = Vue.prototype.$vDict('search.text_amount_coworkers.text', { amount: val.length });
  }

  return res;
}

function getDateMonthPeriodBadge(value) {
  let res = '';

  if (typeof value?.gteOffsetMonths === 'number' || typeof value?.lteOffsetMonths === 'number') {
    const fromDateMonth = typeof value.gteOffsetMonths === 'number' ? formatDateTime(DateTime.now().plus({ months: value.gteOffsetMonths }), { toFormat: formatDateMonth }) : '∞';
    const toDateMonth = typeof value.lteOffsetMonths === 'number' ? formatDateTime(DateTime.now().plus({ months: value.lteOffsetMonths }), { toFormat: formatDateMonth }) : '∞';

    res = `${fromDateMonth} - ${toDateMonth}`;
  }

  return res;
}

function getBadgeStatuses(value, statuses) {
  let res = '';

  if (!value || value.length === 0) {
    return res;
  }

  if (value.length === statuses.length) {
    return Vue.prototype.$vDict('global.text_anyone.text');
  }

  if (value.length === 1 && statuses.length > 0) {
    const item = statuses.find(el => value.includes(el.id));

    if (item && item.name) {
      res = item.name;
    }
  } else {
    res = Vue.prototype.$vDict('search.text_amount_statuses.text', { amount: value.length });
  }

  return res;
}

function getBadgeTags(value, tags = []) {
  let res = '';
  let number;

  if (tags.length > 0) {
    number = value ? value.filter(tagId => {
      return tags.find(tag => tag.id === tagId);
    }).length : 0;
  } else {
    number = value ? value.length : 0;
  }

  if (number > 0) {
    res = Vue.prototype.$vDict('search.tags_result.text', { number });
  } else {
    res = Vue.prototype.$vDict('global.text_all.text');
  }

  return res;
}

function getBadgeDateRange(value) {
  return getResultTextFromDateValue(value, {
    parseFrom: 'fromSQL',
    toFormat: vars.LUXON_FORMAT_SHORT_DATE,
  });
}

function getBadgeDatePeriod(value) {
  let res = Vue.prototype.$vDict('search.whole_period.text');

  if (value) {
    const from = value.gte ? formatDateTime(value.gte, { parseFrom: 'fromSQL', toFormat: vars.LUXON_FORMAT_MONTH_YEAR }) : '';
    const to = value.lte ? formatDateTime(value.lte, { parseFrom: 'fromSQL', toFormat: vars.LUXON_FORMAT_MONTH_YEAR }) : '';

    res = `${from} - ${to}`;
  }

  return res;
}

function getBadgeDateRangeSlider(value) {
  let res = '';

  if (value?.gteOffsetWeeks || value?.lteOffsetWeeks) {
    const fr = getWeekLabel(value.gteOffsetWeeks);
    const to = getWeekLabel(value.lteOffsetWeeks);

    res = `${fr} - ${to}`;
  }

  return res;
}

function getBadgeSourceType(value, items = []) {
  let res = Vue.prototype.$vDict('search.source_types_all.text');

  if (value) {
    if (value.length > 1) {
      res = Vue.prototype.$vDict('search.text_amount_source_types.text', { amount: value.length });
    } else if (value.length === 1) {
      res = items.find(el => el.id === value[0]).name;
    }
  }

  return res;
}

function getBadgeCompanyRoles(value, items) {
  let res = '';
  const maxLength = 20;

  if (value && value.length > 0) {
    const firstRole = findById(items, Math.abs(value[0]));
    let roleName = '';

    if (firstRole && firstRole.name) {
      const { name } = firstRole;
      roleName = name.length > maxLength || value.length > 1 ? `${name.substr(0, maxLength)}...` : name;
    }

    if (roleName) {
      res = value.length > 1 ? `${roleName} (${value.length})` : roleName;
    }
  }

  return res;
}

export function getResultTextBadge(badgeTypeId, value, items = []) {
  let res = null;

  switch (badgeTypeId) {
    case enums.RESULT_BADGE_TYPES.CLIENT_USERS:
      res = getBadgeResponsibleContact(value, items);
      break;
    case enums.RESULT_BADGE_TYPES.DATE_MONTH_PERIOD:
      res = getDateMonthPeriodBadge(value);
      break;
    case enums.RESULT_BADGE_TYPES.BUDGET_RANGE:
      res = getBadgeBudget(value);
      break;
    case enums.RESULT_BADGE_TYPES.STATUSES:
      res = getBadgeStatuses(value, items);
      break;
    case enums.RESULT_BADGE_TYPES.TAGS:
      res = getBadgeTags(value, items);
      break;
    case enums.RESULT_BADGE_TYPES.DATE_RANGE:
      res = getBadgeDateRange(value);
      break;
    case enums.RESULT_BADGE_TYPES.DATE_TIME_RANGE:
      res = getBadgeDateRange(value);
      break;
    case enums.RESULT_BADGE_TYPES.DATE_WEEKS_PERIOD:
      res = getBadgeDateRangeSlider(value);
      break;
    case enums.RESULT_BADGE_TYPES.SOURCE_TYPES:
      res = getBadgeSourceType(value, items);
      break;
    case enums.RESULT_BADGE_TYPES.DATE_PERIOD:
      res = getBadgeDatePeriod(value);
      break;
    case enums.RESULT_BADGE_TYPES.COMPANY_ROLES:
      res = getBadgeCompanyRoles(value, items);
      break;
    case enums.RESULT_BADGE_TYPES.MONTH_OFFSET:
      res = getResultTextFromDateValue(value, { toFormat: vars.LUXON_FORMAT_MONTH_YEAR });
      break;
    default:
  }

  return res;
}

export function getResultCountBadge(value) {
  const res = {
    includes: 0,
    excludes: 0,
  };
  let isEmpty = isEmptyValue(value);

  if (isEmpty) {
    return res;
  }

  if (Array.isArray(value)) {
    res.includes += value.filter(val => !Number.isInteger(val) || val > 0).length;
    res.excludes += value.filter(val => val < 0).length;
  } else {
    res.includes += 1;
  }

  return res;
}

export function getMultipleResultCountBadge(value) {
  if (!value || typeof value !== 'object') {
    return {};
  }

  return Object.entries(value).reduce((acum, [key, val]) => {
    const countBadge = getResultCountBadge(val);

    return {
      includes: acum.includes + (countBadge?.includes || 0),
      excludes: acum.excludes + (countBadge?.excludes || 0),
    };
  }, { includes: 0, excludes: 0 });
}

export function getResultBadges(badgeData) {
  let res = [];

  if (!badgeData) {
    return res;
  }

  if (typeof badgeData === 'object') {
    res = Object.entries(badgeData)
      .filter(([badgeKey, badgeValue]) => badgeValue)
      .reduce((acum, [badgeKey, badgeValue]) => ([
        ...acum,
        {
          key: badgeKey,
          text: badgeValue,
          class: badgeKey === 'excludes' ? 'badge--exclude' : null,
        },
      ]), []);
  } else {
    res = [{
      text: badgeData,
      class: null,
    }];
  }

  return res;
}

export function getValueByOperator(value, operator, type) {
  const operatorKey = getOperatorKey(operator, type);
  let val;
  let res;

  switch (operator) {
    case enums.FILTER_OPERATORS.GTE:
    case enums.FILTER_OPERATORS.LTE:
      if (Array.isArray(value)) {
        res = operator === enums.FILTER_OPERATORS.GTE ? value[0] : value[1];
      } else {
        res = _.get(value, operatorKey);
      }
      break;
    case enums.FILTER_OPERATORS.IN:
      res = Array.isArray(value) ? value.filter(el => typeof el !== 'number' || el > 0) : [value];
      break;
    case enums.FILTER_OPERATORS.NOT_IN:
      val = value.filter(el => el < 0).map(el => el * -1);

      if (val.length > 0) {
        res = val;
      }
      break;
    default:
      res = value;
  }

  return res;
}

export function getOperatorKey(operator, type) {
  let res;

  switch (type) {
    case enums.FILTER_VALUE_TYPES.OFFSET_DAYS:
      res = operator === enums.FILTER_OPERATORS.GTE ? 'gteOffsetDays' : 'lteOffsetDays';
      break;
    case enums.FILTER_VALUE_TYPES.OFFSET_WEEKS:
      res = operator === enums.FILTER_OPERATORS.GTE ? 'gteOffsetWeeks' : 'lteOffsetWeeks';
      break;
    case enums.FILTER_VALUE_TYPES.OFFSET_MONTHS:
      res = operator === enums.FILTER_OPERATORS.GTE ? 'gteOffsetMonths' : 'lteOffsetMonths';
      break;
    default:
      res = operator;
  }

  return res;
}

export function convertSettingsToSearch(settings) {
  const data = {};

  if (settings.search) {
    data.keywords = settings.search;
  }

  settings.filters.forEach(filter => {
    const { field, operator, value, type } = filter;
    const op = operator.toLowerCase();
    const opKey = getOperatorKey(op, type);

    switch (op) {
      case enums.FILTER_OPERATORS.GTE:
      case enums.FILTER_OPERATORS.LTE:
        data[field] = Object.assign({}, data[field], {
          [opKey]: value,
        });
        break;
      case enums.FILTER_OPERATORS.NOT_IN:
        data[field] = (data[field] || []).concat(value.map(el => -el));
        break;
      default:
        data[field] = value;
    }
  });

  return data;
}

export function convertSearchToSettings(scheme, searchData) {
  const settings = {
    search: null,
    filters: [],
  };

  Object.entries(searchData).forEach(([fieldKey, fieldValue]) => {
    if (fieldKey === 'keywords') {
      settings.search = fieldValue;
    } else {
      const schemeData = scheme[fieldKey];

      if (schemeData) {
        const { operators, type } = schemeData;

        operators.forEach(operator => {
          const value = getValueByOperator(fieldValue, operator, type);

          if (!isEmptyValue(value)) {
            settings.filters.push({
              field: fieldKey,
              operator,
              value,
              type,
            });
          }
        });
      }
    }
  });

  return settings;
}

export default {
  getResultTextBadge,
  getResultCountBadge,
  getResultBadges,
  getMultipleResultCountBadge,
};

import Vue from "vue";
import {
  getModules,
} from "@/api/repositories/systemRepository";

const getResultModules = (modules = []) => {
  return modules.map(module => {
    const moduleKey = module.module_key || '';

    return {
      ...module,
      name: Vue.prototype.$vDict(`system_modules.addon_${moduleKey.toLowerCase()}.text`),
    };
  });
};

const initialState = () => ({
  modules: {
    value: [],
    isInited: false,
  },
});

export default {
  state: initialState,
  getters: {
    modules(state) {
      return state.modules.value;
    },
    getModules: state => (payload = {}) => {
      const { tags } = payload;
      let modules = state.modules.value;

      if (tags) {
        modules = modules.filter(module => module.tags.some(tag => tags.includes(tag)));
      }

      return modules;
    },
  },
  mutations: {
    SET_MODULES(state, modules = []) {
      state.modules.value = modules;
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async fetchModules({ state, commit }) {
      if (state.modules.isInited) {
        return state.modules.value;
      }

      let modules = [];

      try {
        const response = await getModules({
          sort: {
            priority: {
              order: 'a',
            },
          },
          offset: 0,
          limit: process.env.VUE_APP_MAX_LIMIT,
        });
        modules = getResultModules(response?.data || []);

        commit('SET_MODULES', modules);
      } catch (error) {
        console.log(error);
      }

      return modules;
    },
  },
  namespaced: true,
};

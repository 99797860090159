import { US_DATE_RANGE_TYPES } from "@/config/enums";
import { getPeriodDaysDate } from "@/shared/usage-stats";

const initialState = () => ({
  period: {
    typeId: US_DATE_RANGE_TYPES.WEEKLY_DATA,
    date: null,
  },
});

export default {
  state: initialState(),
  getters: {
    getPeriod(state) {
      const { typeId, date } = state.period;
      return {
        typeId,
        date: typeId === US_DATE_RANGE_TYPES.CUSTOM_RANGE_DATA ? date : getPeriodDaysDate(typeId),
      };
    },
  },
  mutations: {
    SET_PERIOD(state, period = {}) {
      state.period = period;
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
  },
  namespaced: true,
};

import Vue from 'vue';
import _ from 'lodash';
import { isJSON } from '@/shared/utils';
import { getDefaultFieldsets } from "@/api/repositories/defaultFieldsetsRepository";
import { EntityListFactory } from "@/entities/EntityListFactory";
import { getFieldsets, putFieldsets, deleteFieldsets } from "@/api/repositories/userFieldsetsRepository";

const initialEntityListData = () => ({
  fieldset: undefined,
  waitForItems: false,
  lastQuery: null,
  navigationData: null, // { ids, total }
  searchFilter: null,
  listFilter: {
    byStatus: {},
    default: undefined,
  },
  sort:  null,
  // sort: {
  //   byStatus: {},
  //   default: undefined,
  // },
  statusId: undefined,
  viewMode: undefined,
  currentPage: undefined,
  scrollY: undefined,
  isShowLockedItems: false,
});

const initialState = () => ({
  dataByEntityLists: {},
});

function getEntityListValue(data = {}, payload) {
  const {
    listTypeId,
    key,
    byStatus,
    statusId,
  } = payload;
  const listValues = data[listTypeId] || initialEntityListData();
  let value = listValues[key];

  if (byStatus) {
    value = statusId ? value.byStatus[statusId] : value.default;
  }

  return value;
}

function setEntityListValue(data = {}, payload = {}) {
  const {
    listTypeId,
    value,
    key,
    byStatus,
    statusId,
  } = payload;

  if (!data[listTypeId]) {
    const initialValues = initialEntityListData();
    Vue.set(data, listTypeId, initialValues);
  }

  const listValues = data[listTypeId];

  if (byStatus) {
    const listValue = listValues[key];

    if (statusId) {
      Vue.set(listValue.byStatus, statusId, value);
    } else {
      Vue.set(listValue, 'default', value);
    }
  } else {
    Vue.set(listValues, key, value);
  }
}

export default {
  state: initialState(),
  getters: {
    getEntityListValue: state => payload => {
      return getEntityListValue(state.dataByEntityLists, payload);
    },
    getListWait: state => payload => getEntityListValue(state.dataByEntityLists, {
      listTypeId: payload.listTypeId,
      key: 'waitForItems',
    }),
    getListFieldset: state => payload => getEntityListValue(state.dataByEntityLists, {
      listTypeId: payload.listTypeId,
      key: 'fieldset',
    }) || [],
    getListFilter: state => payload => {
      return getEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'listFilter',
        byStatus: true,
        statusId: payload.statusId,
      });
    },
    getListSort: state => payload => {
      return getEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'sort',
        // byStatus: true,
        // statusId: payload.statusId,
      });
    },
  },
  mutations: {
    SET_LIST_VALUE(state, payload) {
      setEntityListValue(state.dataByEntityLists, payload);
    },
    SET_LIST_FIELDSET(state, payload) {
      setEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'fieldset',
        value: payload.fieldset,
      });
    },
    RESET_LIST_FIELDSET(state, payload) {
      setEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'fieldset',
        value: [],
      });
    },
    SET_LIST_WAIT(state, payload) {
      setEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'waitForItems',
        value: payload.value,
      });
    },
    SET_LIST_FILTER(state, payload) {
      setEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'listFilter',
        value: payload.value,
        byStatus: true,
        statusId: payload.statusId,
      });
    },
    SET_LIST_SORT(state, payload) {
      setEntityListValue(state.dataByEntityLists, {
        listTypeId: payload.listTypeId,
        key: 'sort',
        value: payload.value,
        // byStatus: true,
        // statusId: payload.statusId,
      });
    },
    RESET_ENTITY_LISTS(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
  },
  actions: {
    async fetchFieldset({ state, commit, getters }, payload) {
      const { listTypeId, countryId, type } = payload;
      const existFieldset = getEntityListValue(state,{
        listTypeId,
        key: 'fieldset',
      });

      if (existFieldset) {
        return Promise.resolve(existFieldset);
      }

      let fieldset = [];
      let response;

      try {
        if (type === 'userSettings') {
          response = await getFieldsets(listTypeId);
          fieldset = response?.fieldset || [];
        } else {
          const query = {
            filter: {
              list_type_id: {
                in: [listTypeId],
              },
              country_id: {
                in: [countryId],
              },
            },
          };

          response = await getDefaultFieldsets(query);
          fieldset = _.get(response, 'data[0].fieldset') || [];
        }

        if (fieldset[0] && isJSON(fieldset[0])) {
          fieldset = JSON.parse(fieldset[0]);
        } else {
          fieldset = fieldset.map(fieldKey => ({ key: fieldKey }));
        }

        commit('SET_LIST_FIELDSET', { listTypeId, fieldset });
      } catch (error) {}

      return new Promise(resolve => resolve(fieldset));
    },
    async updateFieldset({ commit, dispatch }, payload) {
      const { listTypeId, fieldset, alternativeListTypeId } = payload;

      if (!fieldset?.length) {
        const requests = [
          dispatch('resetFieldset', { listTypeId }),
        ];

        if (alternativeListTypeId) {
          requests.push(dispatch('resetFieldset', { listTypeId: alternativeListTypeId }));
        }

        return await Promise.all(requests);
      }

      const requestsData = [
        {
          listTypeId,
          fieldset,
        }
      ];

      if (alternativeListTypeId) {
        const entityList = EntityListFactory.create(alternativeListTypeId);

        entityList.initFieldsSections();
        entityList.initAllFields();

        const requestFieldset = fieldset.filter(field => entityList.allFields.findById(field.key));

        requestsData.push({
          listTypeId: alternativeListTypeId,
          fieldset: requestFieldset,
        });
      }

      const requests = requestsData.map(requestData => putFieldsets(requestData.listTypeId, {
        fieldset: requestData.fieldset.length > 0 ? [JSON.stringify(requestData.fieldset)] : [],
      }));

      try {
        const response = await Promise.all(requests);

        if (response) {
          commit('SET_LIST_FIELDSET', {
            listTypeId,
            fieldset,
          });
        }
      } catch (error) {}
    },
    async resetFieldset({ commit }, payload) {
      const { listTypeId } = payload;

      try {
        const response = await deleteFieldsets(listTypeId);

        if (response) {
          commit('RESET_LIST_FIELDSET', { listTypeId });
        }
      } catch (error) {}
    },
  },
  // namespaced: true,
};

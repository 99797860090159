// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill';
import wb from "./registerServiceWorker";
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue'
import _ from 'lodash';
import VueCookies from 'vue-cookies';
import store from './store';
import router from './router/index';
import App from './App';
import enums from '@/config/enums';
import vars from '@/config/vars';
import SvgIcon from '@/components/SvgIcon';
import VSpinner from '@/components/VSpinner';
import VTooltip from '@/components/VTooltip';
import Loading from '@/components/Loading';
import {
  NotifToastr,
  Dictionary,
  AppTheme,
  UserLikeChat,
} from '@/plugins';
import { getCookie } from '@/shared/utils';

// Directives
Vue.directive('showWrapper', {
  bind(el, bindings) {
    bindings.def.wrap = wEl => {
      // Find all next siblings with data-moved and move back into el
      while (wEl.nextElementSibling && wEl.nextElementSibling.dataset.moved) {
        wEl.appendChild(wEl.nextElementSibling).removeAttribute('data-moved');
      }
      wEl.hidden = false;
    };

    bindings.def.unwrap = wEl => {
      // Move all children of el outside and mark them with data-moved attr
      Array.from(wEl.children).reverse().forEach(child => {
        wEl.insertAdjacentElement('afterend', child).setAttribute('data-moved', true);
      });
      wEl.hidden = true;
    };
  },

  inserted(el, bindings) {
    bindings.def[bindings.value ? 'wrap' : 'unwrap'](el);
  },

  update(el, bindings) {
    bindings.def[bindings.value ? 'wrap' : 'unwrap'](el);
  },
});

// Global components
Vue.component('svg-icon', SvgIcon);
Vue.component('v-spinner', VSpinner);
Vue.component('v-tooltip', VTooltip);
Vue.component('loading', Loading);

// Lodash
Object.defineProperty(Vue.prototype, '$_', { value: _ });

// todo
// cssVars()
Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.use(NotifToastr);
Vue.use(Dictionary, { store });
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.use(AppTheme, { store });
Vue.use(UserLikeChat);

// Global vars
Vue.prototype.$notifUnexpectedError = () => {
  if (!Vue.prototype.$notifToastr || !Vue.prototype.$vDict) {
    return;
  }

  Vue.prototype.$notifToastr('error', Vue.prototype.$vDict('errors.unexpected_error.text') || vars.TEXT_DEFAULT_UNEXPECTED_ERROR);
};
Vue.prototype.$http = axios;
Vue.prototype.$workbox = wb;
Vue.prototype.$getEnumsList = (enumKey) => {
  if (!enums[enumKey]) {
    return [];
  }

  const properties = enums[enumKey].properties || {};

  return Object.entries(enums[enumKey])
    .filter(([key, id]) => key !== 'properties')
    .map(([key, id]) => {
      const enumProps = properties[id] || {};

      return {
        id,
        name: Vue.prototype.$vDict(`enums.${enumKey.toLowerCase()}_${key.toLowerCase()}.text`) || key,
        ...enumProps,
      };
    });
};
Vue.prototype.$isPWA = window.matchMedia('(display-mode: standalone)').matches;

//
const hostnameParts = window.location.hostname.split('.');

window.enums = enums;
window.vars = vars;
window.envMode = process.env.VUE_APP_ENV_MODE || getCookie('environment') || enums.ENV_MODES.PRODUCTION;
window.logged_as_user = hostnameParts[0] === 'user';
window.highLevelDomain = window.location.hostname.split('.').slice(-1).join('.');

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: {
    App,
  },
  template: '<App/>',
});

import { formatDateTime, isNumber } from "@/shared/utils";
import { DateTime } from "luxon";

export function getResultTextFromDateValue(value = {}, opts) {
  if (!value) {
    return '';
  }

  const now = DateTime.now();
  const infinity = '∞';
  let fromDate = '';
  let toDate = '';
  let res = '';

  if (isNumber(value.gteOffsetDays) || isNumber(value.lteOffsetDays)) {
    fromDate = isNumber(value.gteOffsetDays) ? formatDateTime(now.plus({ days: value.gteOffsetDays }), opts) : infinity;
    toDate = isNumber(value.lteOffsetDays) ? formatDateTime(now.plus({ days: value.lteOffsetDays }), opts) : infinity;
  } else if (isNumber(value.gteOffsetMonths) || isNumber(value.lteOffsetMonths)) {
    fromDate = isNumber(value.gteOffsetMonths) ? formatDateTime(now.plus({ months: value.gteOffsetMonths }), opts) : infinity;
    toDate = isNumber(value.lteOffsetMonths) ? formatDateTime(now.plus({ months: value.lteOffsetMonths }), opts) : infinity;
  } else if (value.gte || value.lte) {
    fromDate = value.gte ? formatDateTime(value.gte, opts) : infinity;
    toDate = value.lte ? formatDateTime(value.lte, opts) : infinity;
  }

  if (fromDate || toDate) {
    res = fromDate === toDate ? (fromDate === infinity ? '' : fromDate) : `${fromDate} - ${toDate}`;
  }

  return res;
}
